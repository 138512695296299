<template>
  <section class="contact-us-section category-page-section">
    <div class="wrapper">
        <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2 >Contact Us</h2>
                <div class="address-section">
                  <img src="../assets/images/category-page-home-icon.svg" alt="" />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                   <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="">Contact us</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img src="../assets/images/darlingg.jpg" alt="" class="ads-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="contactus-heading-div">
              <h2 class="contactus-main-heading">Get In Touch</h2>
              <p class="contactus-sub-heading">
                Please select a topic below related to your inquiry. If you 
                <span>don't find what you need, fill out our contact form.</span>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="contact-us-main-div">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="left-side-contactus">
                  <form @submit.prevent="validateForm">
                    <input
                      required
                      type="text"
                      placeholder="Enter Your First Name"
                      v-model="firstName"
                    />
                    <input
                      type="text"
                      placeholder="Enter Your Last Name"
                      v-model="lastName"
                    />
                    <input
                      required
                      type="email"
                      placeholder="Enter Your Email"
                      v-model="email"
                    />

                    <input
                      type="text"
                      v-model="phoneNumber"
                      @keypress="isNumber"
                      placeholder="Enter Your Phone Number"
                      maxlength="10"
                      class="phone-input"
                    />
                    <p v-if="phoneError" class="error-message">
                      Phone number must be exactly 10 digits.
                    </p>

                    <textarea
                      rows="6"
                      cols="50"
                      placeholder="Please Leave Your Comments Here..."
                      required
                      v-model="comments"
                    ></textarea>
                    <button class="contactus-button">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="right-side-contactus">
                  <h3 class="label-name">Address</h3>
                  <div class="address-div-sec">
                    <div class="location-icon-div">
                      <img
                        src="../assets/images/contactus-component/location.svg"
                        alt=""
                      />
                    </div>
                    <p class="contact-info">
                      M/S. Vayal Agro Tech (Opc) Private Limited
                      <span>28 Ncn Garden,</span>
                      <span>Thirunagar (Opposite to Muthu Mahal ),</span>
                      <span>Gobichettipalayam,</span>
                      <span>Erode, Tamil Nadu.</span>
                    </p>
                  </div>
                  <h3 class="label-name">Email Id</h3>
                  <div class="address-div-sec">
                    <div class="location-icon-div">
                      <img
                        src="../assets/images/footer-component/Mail-icon.svg"
                        alt=""
                      />
                    </div>
                    <p class="contact-info">
                      <span>support@vayalagro.com</span>
                    </p>
                  </div>
                  <div class="contactus-img-div">
                    <img
                      src="../assets/images/contactus-component/contactus-background.jpg"
                      class="contactus-img"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
            <div class="map-div">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d988.3330994120984!2d77.41775309143928!3d11.458840452428094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDI3JzMxLjgiTiA3N8KwMjUnMDQuNiJF!5e0!3m2!1sen!2sin!4v1691988195977!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
  </section>
</template>

<script>
export default {
  name: "ContactUsPage",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      comments: "",
      phoneError: false,
    };
  },
  methods: {
    isNumber(event) {
      const key = event.key;

      if (!/^[0-9]$/.test(key)) {
        event.preventDefault();
      }
    },
    validateForm() {
   
      if (this.phoneNumber.length !== 10) {
        this.phoneError = true;
        return;
      }
      this.phoneError = false;
      alert("Form submitted successfully!");

      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
      this.comments = "";
    },
  },
};
</script>



<style>
@import url("/src/components/ContactUsPage.scss");
@import url("/src/components/CategoryPage.scss");
</style>