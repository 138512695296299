<template>
    <section class="frequently-section">
        <div class="wrapper">
            <div class="row">
                <!-- Questions Section -->
                <div class="col-lg-7 col-md-7 col-sm-12">
                    <div class="frequent">
                        <div class="question-head">
                            <h2>Most Frequently Asked Questions About The Farm.</h2>
                        </div>
                        <div
                            v-for="(question, index) in questions"
                            :key="index"
                            class="question-box"
                            @click="toggleQuestion(index)"
                        >
                            <div class="question-header">
                                <p>{{ question.text }}</p>
                                <span class="toggle-icon" :class="{ open: question.isOpen }">+</span>
                            </div>
                            <div class="answer" v-if="question.isOpen">
                                <p>{{ question.answer }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Image Section -->
                <div class="col-lg-5 col-md-5 col-sm-12">
                    <div class="frequent-right">
                        <div class="tree">
                            <img src="../assets/images/Faq-tree.png" alt="" />
                        </div>
                        <div class="tree-text">
                            <p>You didn't find your question? See more questions and ask us today?</p>
                            <button> <a href="/contact">Contact Us</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            questions: [
                {
                    text: "Lorem Ipsum is simply dummy text of the printing?",
                    answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry",
                    isOpen: true, // The first question is open by default
                },
                {
                    text: "Why do we use it?",
                    answer: "It is a long established fact that a reader will be distracted by readable content.",
                    isOpen: false,
                },
                {
                    text: "Where does it come from?",
                    answer: "Contrary to popular belief, Lorem Ipsum is not simply random text.",
                    isOpen: false,
                },
                {
                    text: "Where can I get some?",
                    answer: "There are many variations of passages of Lorem Ipsum available.",
                    isOpen: false,
                },
            ],
        };
    },
    methods: {
        toggleQuestion(index) {
            this.questions.forEach((question, i) => {
                if (i === index) {
                    question.isOpen = !question.isOpen; // Toggle the clicked question
                } else {
                    question.isOpen = false; // Close all other questions
                }
            });
        },
    },
};
</script>

<style scoped>
@import url("/src/components/FrequentlyComponent.scss");
</style>
