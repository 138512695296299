var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('section',{staticClass:"footer-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-4 col-md-4"},[_c('ul',{staticClass:"quick-links"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About")])],1),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])]),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4 first-sec"},[_c('div',{staticClass:"foot-logo"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/images/vayal-new-logo.png"),"alt":"Vayal logo","loading":"lazy"}}),_c('h3',{staticClass:"brand-name"},[_vm._v("VAYAL"),_c('span',[_vm._v("AGRO")]),_vm._v("™")])]),_c('p',{staticClass:"foot-desc"},[_vm._v("VayalAgro - Agriculture Classifieds, Buy, Sell, Daily & History Market Prices,updates and news")]),_c('div',{staticClass:"social-icons"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../assets/images/footer-component/whatsapp-footer.svg"),"alt":"whatsapp"}})]),_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../assets/images/footer-component/instagram-footer.svg"),"alt":"instagram"}})])]),_c('p',{staticClass:"download"},[_vm._v("Download App")]),_c('div',{staticClass:"foot-logo d-flex align-items-center"},[_c('img',{staticClass:"play-logo",attrs:{"src":require("../assets/images/googleplay.svg"),"alt":"get play"}})]),_c('p',{staticClass:"copy"},[_vm._v("Copyright © "),_c('span',{attrs:{"id":"year"}}),_vm._v(" Vayal. All Rights Reserved.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("Blogs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/marketprice"}},[_vm._v("Market Price")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/privacy"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("Terms Of Use")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("Responsible Disclosure Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4"},[_c('ul',{staticClass:"contact-info"},[_c('div',{staticClass:"address-section"},[_c('img',{attrs:{"src":require("../assets/images/footer-component/icon.svg"),"alt":""}}),_c('p',{staticClass:"footer-add"},[_vm._v("M/S. Vayal Agro Tech (Opc) Pvt Ltd, 28 Ncn Garden , Thirunagar (Opp to Muthu Mahal ) , Gobichettipalayam, Erode , Tamil Nadu.")])]),_c('div',{staticClass:"address-section"},[_c('img',{attrs:{"src":require("../assets/images/footer-component/Mail-icon.svg"),"alt":""}}),_c('li',[_vm._v("support@vayalagro.com")])]),_c('div',{staticClass:"foot-bot-img"},[_c('img',{attrs:{"src":require("../assets/images/footer-component/Group.png"),"loading":"lazy"}})])])])
}]

export { render, staticRenderFns }