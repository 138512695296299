<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2 v-if="this.$route.params.categoryName">
                  {{ this.$route.params.categoryName.replace(/-/g, " ") }}
                </h2>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">
                   <router-link
  :to="
    this.$route.params.category === 'agriculture-products'
      ? `/${this.$route.params.location}/agriculture-products`
      : this.$route.params.category === 'agriculture-equipments'
      ? `/${this.$route.params.location}/agriculture-equipments`
      : `/${this.$route.params.location}/livestocks`
  "
>
  {{
    this.$route.params.category === 'agriculture-products'
      ? 'Agriculture Products'
      : this.$route.params.category === 'agriculture-equipments'
      ? 'Agriculture Equipments'
      : this.$route.params.category === 'livestocks'
      ? 'Livestocks'
      : this.$route.params.category
  }}
</router-link>

                  </p>
                  <!-- <img src="../assets/images/next-page.svg" alt="" class="next-page">
                                <p class="footer-add">&nbsp;&nbsp;Dairy Product </p> -->
                </div>
                <!-- <img src="../assets/images/category-page-home-icon.svg" alt=""><span>Home</span> &nbsp; <img src="../assets/images/next-page.svg" alt=""> &nbsp;<span>Product Category </span> -->
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img
                src="..//assets/images/darlingg.jpg"
                alt=""
                class="ads-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12 order-2 order-lg-1">
            <SidebarComponent />
            <NewproductComponent />
          </div>
          <div class="col-lg-9 col-md-9 col-sm-12 order-1 order-lg-2">
            <div v-if="isLoading">
              <div class="loader">
                <div class="row">
                  <div
                    v-for="data in 4"
                    :key="data.id"
                    class="col-6 col-sm-6 col-md-4 col-lg-3"
                  >
                    <p class="shimmer-line-img"></p>
                    <p class="shimmer-line"></p>
                    <p class="shimmer-line"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="subcategorys" v-else>
              <div class="row mt-4">
                <div
                  class="col-6 col-sm-6 col-md-4 col-lg-3"
                  v-for="subCategory in responseData.data"
                  :key="subCategory.sub_category_id"
                >
                  <div class="subcategory-grid" @click="navigateToAllList(subCategory.sub_category_name)">
                    <div class="sub-cate">
                      <img
                        :src="
                          subCategory.images[0] ||
                          require('../assets/images/No-Data-list.png')
                        "
                        alt="Subcategory Image"
                        class="sub-img"
                      />
                    </div>
                    <h4>{{ subCategory.sub_category_name }}</h4>
                    <p>{{ subCategory.list }} Items</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { en, ta } from "../translations";
import { useItemStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";
import NewproductComponent from "./NewproductComponent.vue";
import SidebarComponent from "./SidebarComponent.vue";
export default {
  name: "SubcategoryPage",
  props: ["selectedLanguage"],
  data() {
    return {
      isLoading: false,
      responseData: null,
      isSidebarOpen: false,
      filterCategories: [],
    };
  },
  created() {
       this.fetchData();
    console.log("dhdd", this.$route.params);
    this.getItemId();

    // this.updateMetaTags(); // Initialize meta tags on component creation
  },
  computed: {
    // Map state from Pinia store to computed properties
    ...mapState(useItemStore, ["categoryId", "subCategoryId"]),

    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },

  watch: {
    categoryId(newVal) {
      this.categoryId = newVal;
      console.log("this.categoryId:", this.categoryId);
    },
    "$route.params": function () {
      this.getItemId();
      this.updateMetaTags(); // Update meta tags whenever route params change
    },
  },
  components: {
    SidebarComponent,
    NewproductComponent,
  },

  methods: {
    async getItemId() {
      const itemStore = useItemStore();
      await itemStore.updateItemId(
        this.$route.params.categoryName?.replace(/-/g, " "),
        "category_name"
      );
      this.fetchData();
    },
    ...mapActions(useItemStore, ["updateItemId"]),

    async fetchData() {
      try {
        this.isLoading = true;
        let apiUrl = "https://vaiyal-app.herokuapp.com/categoryweb";

        if (this.$route.params.location !== "tamilnadu") {
          apiUrl += `?marketname=${this.$route.params.location}`;
        }

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryid: this.categoryId,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.responseData = data || []; // Assuming 'categories' is the key with the list
        console.log("Fetched categories:", this.responseData);

        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },
       navigateToAllList(subcategoryName) {
      const location = this.$route.params.location; // Assuming location is available in your current route params
      this.$router.push({
        name: "ListComponent",
        params: {
          category: this.$route.params.category,
          categoryName: this.$route.params.categoryName,
          product: subcategoryName.replace(/\s+/g, "-").toLowerCase(),
          location: location
        }
      });
    },
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
</style>
