<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2>Market Price</h2>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">Market Price</p>
                  <!-- <img src="../assets/images/next-page.svg" alt="" class="next-page">
                                <p class="footer-add">&nbsp;&nbsp;Dairy Product </p> -->
                </div>
                <!-- <img src="../assets/images/category-page-home-icon.svg" alt=""><span>Home</span> &nbsp; <img src="../assets/images/next-page.svg" alt=""> &nbsp;<span>Product Category </span> -->
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img
                src="..//assets/images/darlingg.jpg"
                alt=""
                class="ads-img"
              />
            </div>
          </div>
        </div>
      </div>

      <section class="marketprice-select">
        <h2>Market Price today in Tamilnadu (23-11-2024)</h2>
        <div class="row">
          <div class="col-lg-10 col-md-10">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>Categories</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <select name="cars" id="cars">
                        <option value="volvo">Select Category</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>District</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <select name="cars" id="cars">
                        <option value="volvo">Select District</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>Cities</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <select name="cars" id="cars">
                        <option value="volvo">Select Cities</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>Date</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <input type="date" value="2024-06-12"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12">
            
            <div class="catgory-button">Search</div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="row">
            <h3>Trend of Market Price Today</h3>
            <div class="col-lg-10 col-md-10 col-sm-12">
                <div class="table-container">

              <table>
                <tr>
                  <th>Categories</th>
                  <th>District</th>
                  <th>Cities</th>
                  <th>Price</th>
                  <th>Units</th>
                  <th>History Price</th>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="category-name-table">
                      <img src="../assets/images/potato.svg" alt="" />
                      <p>Potato</p>
                    </div>
                  </td>
                  <td>Coimbatore</td>
                  <td>Mettupalayam</td>
                  <td class="amt">Rs. 49.15</td>
                  <td class="weight">1 Kg</td>
                  <td>
                    <button>
                      <a href="/historyprice">
                      <img src="../assets/images/history.svg" alt="" class="history-price"/>
                      &nbsp;View
                      </a>
                    </button>
                  </td>
                </tr>
                
              </table>
              
                </div>
              <div class="page-nation">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>Showing 1-16 of 60 item(s)</p>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="page-btns">
                      <button>1</button>
                      <button>2</button>
                      <button>3</button>
                      <button>
                        Next <i class="fa-solid fa-angle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-12 vertical-add">
              <div><img src="../assets/images/side-ads.png" alt="" /></div>
              <div class="mt-3">
                <img src="../assets/images/side-ads.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 bottom-ads">
      <AdsComponent />
    </div>
  </section>
</template>

<script>
import AdsComponent from "./AdsComponent.vue";
export default {
  components: {
    AdsComponent,
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
@import url("/src/components/Marketprice.scss");
</style>
