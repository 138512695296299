<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2>History Price</h2>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">History Price</p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">Potato</p>
                </div>
                <!-- <img src="../assets/images/category-page-home-icon.svg" alt=""><span>Home</span> &nbsp; <img src="../assets/images/next-page.svg" alt=""> &nbsp;<span>Product Category </span> -->
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img
                src="..//assets/images/darlingg.jpg"
                alt=""
                class="ads-img"
              />
            </div>
          </div>
        </div>
      </div>

      <section class="marketprice-select">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="catgory">
                  <h5>District</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <select name="cars" id="cars">
                        <option value="volvo">Select District</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="catgory">
                  <h5>Cities</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <select name="cars" id="cars">
                        <option value="volvo">Select City</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-12">
                <div class="catgory-button">Search</div>
              </div>
            </div>
          </div>
        </div>
        <div class="trend-market">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <div class="trend-graph">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <h4>Historical Potato Rates in Coimbatore for the Last 10 Days</h4>
                      </div>
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="graph-section">
                          <button  @click="triggerApiPost" class="api-button">Graph View</button>

                          <div class="select-section">
                            <select name="cars" id="cars">
                              <option value="volvo">Nov</option>
                              <option value="saab">Jan</option>
                              <option value="opel">Feb</option>
                              <option value="audi">Aug</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-container" v-show="!showGraphView">
                  <table>
                    <tr>
                      <th>Categories</th>
                      <th>District</th>
                      <th>Cities</th>
                      <th>Price</th>
                      <th>Units</th>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                   <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>Coimbatore</td>
                      <td>Mettupalayam</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                  </table>
                </div>
                  <div class="graph-view" v-show="showGraphView">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="graph-price">
                             <span>Price 23 Nov 2024</span>
                             <p>Rs.49.15</p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="monthly-view">
                            <span>Weekly</span>
                            <span>Monthly</span>
                            <span>Annually</span>
                          </div>
                        </div>
                      </div>
                    </div>
      <div>
      <apexchart 
      class="chart"    
        type="area"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    </div>
                <div class="top-cities" v-show="!showGraphView">
                  <h3>Potato Rates in Top Cities</h3>
                </div>
                <div class="more-cities" v-show="!showGraphView">
                  <h6>Search for more cities</h6>
                </div>

                <div class="table-container mt-4 history-table" v-show="!showGraphView">
                  <table>
                    <tr>
                      <th>Cities</th>
                      <th>Categories</th>
                      <th>Date</th>
                      <th>Price</th>
                      <th>Units</th>
                    </tr>
                    <tr>
                      <td>Mettupalayam</td>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>22-Nov-2024</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>Mettupalayam</td>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>22-Nov-2024</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>Mettupalayam</td>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>22-Nov-2024</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>Mettupalayam</td>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>22-Nov-2024</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                    <tr>
                      <td>Mettupalayam</td>
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/potato.svg" alt="" />
                          <p>Potato</p>
                        </div>
                      </td>
                      <td>22-Nov-2024</td>
                      <td class="amt">Rs. 49.15</td>
                      <td class="weight">1 Kg</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="col-lg-2 col-md-2 col-sm-12 vertical-add">
                <div><img src="../assets/images/side-ads.png" alt="" /></div>
                <div class="mt-3">
                  <img src="../assets/images/side-ads.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import VueApexCharts from 'vue-apexcharts'
  export default {
    name: "PostApi",
    components: {
    apexchart: VueApexCharts,
  },
    data() {  
      return {
         showGraphView: false,
        responseMessage: null, // To display API response or errors
        chartOptions: {
        chart: {
          type: "area",
          height: 350,
          stacked: false,
          zoom: {
            enabled: false,
          },
          
          toolbar: {
            show: false,
          },
          
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ["#009954"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 6,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            colorStops: [
              {
                offset: 0,
                color: "#29AB87",
                opacity: 0.7,
              },
              {
                offset: 100,
                color: "#FFFFFF",
                opacity: 0.2,
              },
            ],
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#009954"],
        },
        tooltip: {
  shared: true,
  theme: "dark",
  
  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    const value = series[seriesIndex][dataPointIndex]; // y value
    const xValue = w.globals.categoryLabels[dataPointIndex]; // Correct way to get the x-axis label

    return `
      <div style="padding: 10px; background: #333; color: #fff; border-radius: 5px;">
        <strong></strong> ${xValue}<br />
        <strong>Rs:</strong> ${value}
      </div>
    `;
  },

},

        yaxis: {
          labels: {
            style: {},
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: "category",
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            stroke: {
              color: "#009954",
              width: 2, 
              dashArray: 0, 
            },
            
            x: {
              axisBorder: {
                show: true,
                color: '#009954',
              },
            },
            y: {
              axisBorder: {
                show: true,
                color: '#009954',
              },
            }
          },
        },
        
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
      },
      series: [], 
    };
  },

    methods: {
      async triggerApiPost() {
       this.showGraphView = true;
        try {
          const postData = {
    subcategoryid: "251",
    marketplaceid: "22"
}
          const response = await axios.post("https://vaiyal-app.herokuapp.com/subcategory/getrecentprice", postData);
            
        //   console.log(response)

          const datas = response.data?.data;
        if (!datas) {
          console.error('Data not found in the response');
          return;
        }

        // console.log("fromdatas",datas)

        const processedData = datas.map(item => ({
          x: item.created, 
          y: item.price || 0, 
        }));


        const formattedData = processedData.map(item => {
         const date = new Date(item.x);
         const day = String(date.getDate()).padStart(2, '0');
         const month = String(date.getMonth() + 1).padStart(2, '0');
         const formattedDate = `${day}-${month}`.replace('-', '/');
         return {
           x: formattedDate,
           y: item.y
         };
        });


        this.series = [
          {
            name: "Product A", 
            data: formattedData,
          }
        ];
          this.responseMessage = `Success: ${response.data.message || "Posted successfully!"}`;
        } catch (error) {
          this.responseMessage = `Error: ${error.response?.data?.message || "Something went wrong!"}`;
        }
      },
    },
  };
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
@import url("/src/components/Marketprice.scss");
</style>
