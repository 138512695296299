<template>
  <section class="header-section">
    <div class="wrapper">
      <div class="col-lg-12">
        <div class="row nav-bar">
          <div class="col-lg-4 col-md-4">
            <div class="header-logo">
              <a href="/" class="d-flex align-items-center">
                <img src="../assets/images/vayal-new-logo.png" alt="" />
                <p>VAYAL<span>AGRO</span>&#8482;</p>
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-8">
            <div class="header-cont">
              <p class="header-content"><img src="../assets/images/header-component/nav-category-icon.svg" alt="">Categories
              </p>
              <p class="header-content"><img src="../assets/images/header-component/nav-mp-icon.svg" alt=""><a href="/marketprice">Market Price</a>
              </p>
              <p class="header-content"><img src="../assets/images/header-component/nav-adver-icon.svg"
                  alt=""><a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors">Advertise Products</a></p>
                  <!-- <p class="header-content"><img src="../assets/images/lang-icon.svg"
                  alt=""><select  class="language-select" v-model="selectedLanguage">
                <option value="en">ENGLISH</option>
                <option value="ta">தமிழ்</option>
              </select></p> -->
            </div>
          </div>
          <div class="mobile-cont">
            <div class="mobile-div">
              <img src="../assets/images/header-component/nav-category-icon.svg" alt="">
              <p class="mbl-header-content">Categories</p>
            </div>

            <div class="mobile-div">
              <div class="login-div">
                <i class="fa-solid fa-plus" style="color: #ffffff;"></i>
              </div>
              <p class="ad-product">Advertise Products</p>
            </div>
            <div class="mobile-div">
              <img src="../assets/images/header-component/nav-mp-icon.svg" alt="">
              <p class="mbl-header-content"><a href="/marketprice">Market Price</a></p>
            </div>
              <!-- <div class="mobile-div">
              <img src="../assets/images/header-component/nav-mp-icon.svg" alt="">
              <label class="m-0 p-0" for="text"> Language:</label>
       
              <select  class="language-select town m-0 ps-0 mx-3 menu-link custom-select" v-model="selectedLanguage">
                <option value="en">ENGLISH</option>
                <option value="ta">தமிழ்</option>
              </select>
            </div> -->
             
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { en, ta } from "../translations.js";

export default {
  name: "HeaderComponent",
  data() {
    return {
      selectedLanguage: "en", // Default language
      translations: en, // Default translations
    };
  },
  created() {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
      this.translations = storedLanguage === "en" ? en : ta;
    }
  },
  watch: {
    selectedLanguage(newLang) {
      localStorage.setItem("selectedLanguage", newLang);

      // Update translations
      this.translations = newLang === "en" ? en : ta;

      // Clear the language after 5 minutes
      setTimeout(() => {
        localStorage.removeItem("selectedLanguage");
      }, 5 * 60 * 1000); // 5 minutes
    },
  },
};
</script>

<style>
@import url("/src/components/Header.scss");
</style>