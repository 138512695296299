<template>
  <section class="side-category-section">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="side-category">
          <h4 class="category-name">Category</h4>
          <span></span>
          <div v-for="(item) in category_data" :key="item.id" class="side-category-list">
            <div class="side-category-image-div">
              <img
                class="side-category-image"
                :src="item.image "
                alt="Category Image"
                loading="lazy"
              />
            </div>
            <div class="side-category-name-div">
              <router-link
                :to="
                  item.hasSubCategory
                    ? {
                        name: 'SubcategoryPage',
                        params: {
                          categoryId: item.category_id,
                          categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                          category:
                            item.category_type === 'Live stocks'
                              ? 'livestocks'
                              : item.category_type
                                  .replace(/\s/g, '-')
                                  .replace('farm', 'agriculture'),
                        },
                      }
                    : {
                        name: 'ListComponent',
                        params: {
                          product: item.name.replace(/\s/g, '-').toLowerCase(),
                          location: $route.params.location,
                          category:
                            item.category_type === 'Live stocks'
                              ? 'livestocks'
                              : item.category_type
                                  .replace(/\s/g, '-')
                                  .replace('farm', 'agriculture'),
                        },
                      }
                "
                :title="`Category: ${item.name}`"
              >
                <p>{{ item.name }}</p>
              </router-link>
            </div>
            <div class="side-category-count-div">
              <p class="side-category-count">{{ item.list }}</p>
            </div>
          </div>
          <div v-if="isLoading" class="loading-spinner">Loading...</div>
          <div v-else-if="!category_data.length" class="no-data">
            No categories available.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SideCategory",
  data() {
    return {
      category_data: [],
      isLoading: true,
      isListPage: true,
    };
  },
  created(){
        this.checkListPage();
this.sendData();
  },
  watch:{
  '$route': function() {
      this.checkListPage();
      
    },
  },
  methods: {
     checkListPage() {
      this.isListPage = this.$route.name === 'ListComponent'
    },
    async sendData() {
      this.isLoading = true;
      try {
        let apiUrl = "https://vaiyal-app.herokuapp.com/categorytype";
        const requestBody = {
          categorytype: this.$route.params.category
            .replace(/-/g, " ")
            .replace("agriculture", "farm"),
        };

        if (this.$route.params.location && this.$route.params.location !== "tamilnadu") {
          apiUrl += `?marketname=${this.$route.params.location}`;
        }

        const response = await axios.post(apiUrl, requestBody, {
          headers: { version: "3.0" },
        });

        const datas = response.data?.data || [];
        this.category_data = datas.map((item) => ({
          id: item.category_id || "N/A",
          image: item.images?.[0] || require("../assets/images/No-Data-list.png"),
          name: item.category_name || "No Name",
          list: item.list || "No List",
          category_type: item.category_type || "",
          hasSubCategory: item.hasSubCategory || false,
        }));
        console.log("this.category_data",this.category_data)
      } catch (error) {
        console.error("Error during POST request:", error);
        this.category_data = [];
      } finally {
        this.isLoading = false;
      }
    },
  },
  
  mounted() {
    this.checkListPage(); // Check on initial load
    this.$watch('$route', this.checkListPage); 
    this.sendData();
  },
};
</script>

<style>
@import url("/src/components/SidebarComponent.scss");

</style>
