<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2 v-if="this.$route.params.product">
                  {{ this.$route.params.product.replace(/-/g, " ") }}
                </h2>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">
                   <router-link
  :to="
    this.$route.params.category === 'agriculture-products'
      ? `/${this.$route.params.location}/agriculture-products`
      : this.$route.params.category === 'agriculture-equipments'
      ? `/${this.$route.params.location}/agriculture-equipments`
      : `/${this.$route.params.location}/livestocks`
  "
>
  {{
    this.$route.params.category === 'agriculture-products'
      ? 'Agriculture Products'
      : this.$route.params.category === 'agriculture-equipments'
      ? 'Agriculture Equipments'
      : this.$route.params.category === 'livestocks'
      ? 'Livestocks'
      : this.$route.params.category
  }}
</router-link>

                  </p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />

                  <p  class="footer-add">
                   <router-link v-if="this.$route.params.categoryName" :to="{ 
     name: 'SubcategoryPage',
        params: { 
          categoryId: productList.category_id, 
          categoryName: productList.category_name, 
          category: productList.category_type === 'live stocks' ? 'livestocks' : productList.category_type,
          location: this.$route.params.location 
        },
  }"
>
  {{ this.$route.params.categoryName.replace(/-/g, ' ') }}
</router-link>

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img src="../assets/images/darlingg.jpg" alt="" class="ads-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3">
        <div class="row" id="row-reverse">
          <div class="col-lg-3 col-md-4 col-sm-12">
            <SidebarComponent />
            <section class="side-category-section">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="side-category">
                    <h4 class="category-name">Location</h4>
                    <span></span>

                    <div class="location-section">
                      <h6>Districts</h6>
                      <div class="district-sec">
                        <div
                          v-for="district in district"
                          :key="district.market_id"
                        >
                          <label
                            class="district-select"
                            @click="getFilterRoute(district.market_name)"
                            >{{ district.market_name }}
                            ({{ district.count }})
                            <input type="radio" name="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <h6>City</h6>
                      <div class="district-sec">
                        <div
                          v-for="city in cities"
                          :key="city.market_id"
                          class="city-sec"
                        >
                          <label
                            class="district-select"
                            @click="getFilterRoute(city.place, true)"
                            >{{ city.place }}
                            ({{ city.count }})
                            <input type="radio" name="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <NewproductComponent />
          </div>
          <div
            class="col-lg-8 col-md-7 col-sm-12"
            v-if="productList.length > 0"
          >
            <div v-if="$route.name === 'ListComponent'">
              <h1 class="my-3 pb-1 searched-for">
                {{ this.$route.params.product }} -
                {{ this.totalLists }} Product's Found
              </h1>
            </div>
            <div class="row">
              <div v-if="isLoading">
                <div class="loader">
                  <div class="row">
                    <div
                      v-for="data in 4"
                      :key="data.id"
                      class="col-lg-3 col-md-4 col-sm-12"
                    >
                      <p class="shimmer-line-img"></p>
                      <p class="shimmer-line"></p>
                      <p class="shimmer-line"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="col-lg-4 col-md-6 col-sm-12"
                v-for="product in productList"
                :key="product.product_id || product.equip_product_id"
              >
              <router-link
  :to="getProductRoute(product, $route.params)"
 
>
                <div class="product">
                  <div class="img_div">
                    <img
                      :src="getProductImage(product)"
                      :alt="`${product.category_name}-${
                        product.product_id || product.equip_product_id
                      }`"
                      @error="handleImageError"
                      loading="lazy"
                    />
                  </div>
                  <p class="type">{{ product.product_category_type }}</p>

                  <div class="align">
                    <p class="sub_category_name">{{ product.category_name }}</p>
                    <p class="product-name">
                      {{ product.name || product.business_name }}
                    </p>
                    <div class="date-location">
                      <div>
                        <p class="loction-data">
                          <span
                            ><img
                              src="../assets/images/location-icon.svg"
                              alt=""
                              loading="lazy" /></span
                          >{{ product.place }}, {{ product.market_name }}
                        </p>
                      </div>
                      <div>
                        <p class="created">
                          {{
                            new Date(product.created).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              }
                            )
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="price-view">
                      <div>
                        <p class="price">
                          <span
                            ><i
                              class="fa-solid fa-indian-rupee-sign"
                              style="color: #018f43"
                            ></i
                          ></span>
                          {{ product.price }}/ {{ product.quantity_type }}
                        </p>
                      </div>
                      <div>
                        <button>View More</button>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
              </div>
            </div>
            <button
              v-if="currentPage < totalPages && !isLoading"
              @click="loadMoreData"
              class="view-more"
            >
              View More
            </button>
            <p v-if="currentPage < totalPages">
              {{ translations.page }} {{ currentPage }} / {{ totalPages }}
            </p>

            <div class="col-lg-12">
               <div 
        v-html="isExpanded ? more : truncatedContent" 
        :id="$style.more" v-if="more.length > 0">
      </div>
      <button v-if="more.length > 400" @click="toggleExpand" class="show-btn">
        {{ isExpanded ? "Show Less" : "Show More" }}
      </button>
                
              </div>
          </div>

          <div class="col-lg-1 col-md-1 col-sm-12 mt-5">
            <div class="vertical-ad">
              <img src="../assets/images/side-ads.png" alt="" />
            </div>
            <div class="vertical-ad">
              <img src="../assets/images/side-ads.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NewproductComponent from "./NewproductComponent.vue";
import SidebarComponent from "./SidebarComponent.vue";
import { en, ta } from "@/translations";
import { disableScroll } from "@/router";
import axios from "axios";
import metaDataJson from "../meta.json";

export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue

  data() {
    return {
      productList: [],
      isLoading: false,
      currentPage: 1,
      totalPages: 1,
      hasMorePages: true,
      district: [],
      cities: [],
      totalLists: 0,
      matchingLocation: null, // New property to store the matching location
      showDeliveryMessage: false,
      isFilterResponsive: false,
      isFilterOpen: false,
      isCity: false,
      details: {},
      selectedCity: "",
      showForm: false,
      isExpanded: false,
    };
  },
  created() {
    this.findMatchingLocation();
    this.fetchProductList();
    this.isCity = false;
    this.$nextTick(() => {
      this.fetchProductList();
    });
    this.updateMetaTags();
    console.log("this.$route.params",this.$route.params)
  },
  components: {
    SidebarComponent,
    NewproductComponent,
  },
  watch: {
    // Watch for changes in the route parameters
    "$route.params.city": {
      handler: "handleRouteChange",
      deep: true,
      // immediate: true,
    },
    "$route.params.location": {
      handler: "handleRouteChange",
      deep: true,
      // immediate: true,
    },
    "$route.params": function () {
      this.updateMetaTags(); // Update meta tags whenever route params change
    },
    isFilterOpen(newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    $route() {
      // Call the fetchProductList method when the route changes
    },
  },
  methods: {
     toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    closeForm() {
      this.isFilterOpen = false;
    },
    openInNewTab(product) {
      const routeData = this.getProductRoute(product, this.$route.params);
      console.log("routeData",routeData);
      const resolvedRoute = this.$router.resolve(routeData);
      window.open(resolvedRoute.href, "_blank");
    },
    handleResize() {
      this.isFilterResponsive = window.innerWidth <= 768;
    },
    toggleFilter() {
      if (this.isFilterResponsive) {
        this.isFilterOpen = !this.isFilterOpen;
      }
    },
    async updateMetaTags() {
      const categoryName = this.$route.params.categoryName;
      // Static page name, as per your structure
      const products = this.$route.params.product.replace(/-/g, " ");
      const district = this.$route.params.location || "Tamilnadu";

      // Function to capitalize the first letter
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const capitalizedDistrict = capitalizeFirstLetter(district || "");
      const capitalizedProduct = capitalizeFirstLetter(products || "");

      try {
        // Find the metadata for the given category
        const metaData = metaDataJson.listPage.find(
          (item) => item.category_name === categoryName
        );

        if (metaData) {
          // Update the title
          const title = metaData.title
            .replace(/{district}/g, capitalizedDistrict)
            .replace(/{products}/g, capitalizedProduct);
          console.log("🚀 ~ updateMetaTags ~ title:", title);
          document.title = title;

          // Update the description
          const description = metaData.description
            .replace(/{district}/g, capitalizedDistrict)
            .replace(/{products}/g, capitalizedProduct); // In case you need to replace the created date
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute("content", description);
          }

          // Update additional meta tags (e.g., title for SEO purposes)
          const head = metaData.title
            .replace(
              "{city}",
              capitalizedDistrict ? `${capitalizedDistrict},` : ""
            )
            .replace("{district}", capitalizedDistrict)
            .replace(/{products}/g, capitalizedProduct);
          const metaHead = document.querySelector('meta[name="title"]');
          if (metaHead) {
            metaHead.setAttribute("content", head);
          }

          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );
          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title);
          }

          const metaOgDescription = document.querySelector(
            'meta[property="og:description"]'
          );
          if (metaOgDescription) {
            metaOgDescription.setAttribute("content", description);
          }
        } else {
          // this.$router.replace({path:"/error"})
          console.warn(`No metadata found for category_name: ${categoryName}`);
        }
      } catch (error) {
        console.warn("Error fetching meta data:", error);
      }
    },
    getProductRoute(product, route = null) {
      let params = { ...route };

      // params.seller = product.seller.replace(/\s+/g, '-').toLowerCase()  || "seller"

      if (product.product_id) {
        params.id = product.product_id;
                console.log("product_id", params);

        return {
          name:
            product.category_type === "live stocks"
              ? "livestockdetails"
              : "DetailComponent",
          params,
        };
      } else if (product.equip_product_id) {
        params.id = product.equip_product_id;
                console.log("equip_product_id", params);

        return { name: 'equipmentdetail', params };
        
      }

      return { name: "fallbackRoute" };
    },
    async translateToEnglish(text) {
      try {
        const response = await fetch(
          `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=en&dt=t&q=${encodeURIComponent(
            text
          )}`
        );
        const data = await response.json();
        return data[0][0][0]; // Extract translated text
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original if translation fails
      }
    },
    isCurrentMarket(marketName, isCity = false) {
      console.log("name->", marketName, isCity);

      let isMatched = isCity
        ? this.$route.params?.city?.replace(/-/g, " ").toLowerCase() ===
          marketName.toLowerCase()
        : this.$route.params?.location?.replace(/-/g, " ").toLowerCase() ===
          marketName.toLowerCase();
      console.log("matched->", isMatched);

      if (isMatched) {
        if (isCity) {
          this.isCity = true;
        }
      }
      return isMatched;
    },

    handleCityClick(place) {
      this.getProductRoute(place);
      this.isFilterOpen = false; // Close the filter sidebar
    },

    getTag(item, index) {
      let style = {};
      if (index === 0) {
        style.color = "black";
        style.fontSize = "28px";
        style.fontWeight = "600";
        return { tag: "h1", style };
      } else if (
        item.key &&
        (item.key.toLowerCase().includes("buy") ||
          item.key.toLowerCase().includes("sell"))
      ) {
        style.fontSize = "23px";
        style.fontWeight = "600";
        return { tag: "h2", style };
      } else {
        style.fontWeight = "600";
        style.fontSize = "20px";
        return { tag: "h3", style };
      }
    },
    getProductImage(product) {
      if (
        product.images &&
        product.images.length > 0 &&
        typeof product.images[0] === "string" &&
        product.images[0].trim() !== ""
      ) {
        return product.images[0];
      } else {
        return require("../assets/images/No-Data-list.png");
      }
    },
    handleImageError(event) {
      event.target.src = require("../assets/images/No-Data-list.png");
    },
    truncatetitle(name) {
      if (name.length > 20) {
        return name.substring(0, 17) + "...";
      }
      return name;
    },
    truncatebtitle(business_name) {
      if (business_name.length > 25) {
        return business_name.substring(0, 25) + "...";
      }
      return business_name;
    },

    getFilterRoute(location, city = false) {
      this.selectedCity = location;
      let params = { ...this.$route.params };
      city
        ? (params.city = this.selectedCity.replace(" ", "-").toLowerCase())
        : ((params.location =
            location.replace(" ", "-").toLowerCase() || "tamilnadu"),
          (params.city = null));

      this.$router.push({
        name: "ListComponent",
        params: params,
      });
    },
      
    async handleRouteChange() {
      this.currentPage = 1;
      this.totalPages = 1;
      this.hasMorePages = true;
      this.isCity = false;
      this.productList = [];
      await this.fetchProductList();
    },
    async findMatchingLocation() {
      const locationParam = this.$route.params?.location?.replace(/-/g, " ");
      const beforeComma = (locationParam ?? "").split(",")[0].trim();

      const response = await axios.get(
        `https://vaiyal-app.herokuapp.com/get/location?search=${this.$route.params?.location?.replace(
          /-/g,
          " "
        )}`
      );

      if (response.data.status === "success") {
        const locationData = response.data.data;

        const matchingLocation = locationData.find(
          ({ market_name, tn_name }) => {
            return (
              market_name?.replace(/-/g, " ").toLowerCase() ===
                beforeComma.replace(/-/g, " ").toLowerCase(),
              tn_name
            );
          }
        );
        if (matchingLocation) {
          this.matchingLocation = matchingLocation;
          // console.log('Matching location found:', matchingLocation);
        } else {
          this.matchingLocation = null;
          // console.log('No matching location found.');
        }
      } else {
        console.error("Error fetching location data:", response.data.msg);
      }
      if (this.$route.params.city) {
        const response = await axios.get(
          `https://vaiyal-app.herokuapp.com/get/location?search=${this.$route.params?.city?.replace(
            /-/g,
            " "
          )}`
        );
        // console.log(response,'dddddddd');

        if (response.data.status === "success") {
          const locationData = response.data.data;
          const matchingCity = locationData.find(({ place, tn_place }) => {
            const placeParts = place?.split(",").map((part) => part.trim());
            return (
              placeParts &&
                placeParts[0].replace(/-/g, " ").toLowerCase() ===
                  beforeComma.replace(/-/g, " ").toLowerCase(),
              tn_place
            );
          });
          if (matchingCity)
            this.matchingLocation = {
              ...this.matchingLocation,
              ...matchingCity,
            };
          else
            console.error(
              "Error fetching city data with list:",
              response.data.msg
            );
        } else {
          console.error("Error fetching city data:", response.data.msg);
        }
      }
    },

    async fetchProductList() {
      try {
        this.isLoading = true;

        const product = this.$route?.params?.product
          ?.replace(/-/g, " ")
          ?.replaceAll("&", "%26");

        // Fetch matching location
        await this.findMatchingLocation();
        const marketId = this.matchingLocation
          ? this.matchingLocation.market_id
          : null;
        const marketPlaceId = this.matchingLocation
          ? this.matchingLocation.market_place_id
          : null;

        // API call
        const response = await axios.post(
          `https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`,
          {
            marketid: marketId,
            marketplaceid: marketPlaceId,
          }
        );

        if (response.data.status === "success") {
          // Update state with API response data
          this.totalPages = response.data.pages;
          this.hasMorePages = this.currentPage < this.totalPages;
          this.district = response.data.district;
          this.cities = response.data.cities;
          this.totalLists = response.data.totalLists;
          this.more = response.data.more;
          this.details = {
            title: response.data.title,
            description: response.data.description,
            tn_title: response.data.tn_title,
            tn_description: response.data.tn_description,
          };

          this.productList =
            this.currentPage === 1
              ? response.data.data
              : [...this.productList, ...response.data.data];

          // Determine category name
          const categoryName = this.productList.map(
            (category) => category.category_name
          );
          const apiCategoryName = categoryName[0]
            ?.replace(/\s+/g, "-")
            .toLowerCase();
          const currentCategoryName = this.$route.params.categoryName;

          // Determine category type
          const apiCategoryType = this.productList[0]?.category_type; // Use the first item's category_type
          const currentCategoryType =
            this.$route.params.category === "agriculture-products"
              ? "Agriculture Products"
              : this.$route.params.category === "agriculture-equipments"
              ? "Agriculture Equipments"
              : this.$route.params.category === "live stocks"
              ? "Livestocks"
              : this.$route.params.category;

          console.log(
            "apiCategoryType:",
            apiCategoryType,
            "currentCategoryType:",
            currentCategoryType
          );

          // Flag for route update
          let needsRouteUpdate = false;
          let newRouteParams = { ...this.$route.params };

          if (apiCategoryName && apiCategoryName !== currentCategoryName) {
            newRouteParams.categoryName = apiCategoryName;
            needsRouteUpdate = true;
          }

          if (apiCategoryType && apiCategoryType !== currentCategoryType) {
            newRouteParams.category =
              apiCategoryType === "live stocks"
                ? "livestocks"
                : apiCategoryType
                    .replace("farm", "agriculture")
                    .replace(/\s+/g, "-")
                    .toLowerCase();
            needsRouteUpdate = true;
          }

          // Perform route update if required
          if (needsRouteUpdate) {
            disableScroll();
            this.$router.replace({ ...this.$route, params: newRouteParams });
          }

          console.log(
            "needsRouteUpdate:",
            needsRouteUpdate,
            "newRouteParams:",
            newRouteParams
          );
        } else {
          console.error("Error fetching product list:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching product list:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async loadMoreData() {
      this.currentPage++;
      await this.fetchProductList();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    locationParts() {
      const locationParam = this.$route.params?.location?.replace(/-/g, " ");
      const [beforeComma, afterComma] = locationParam
        .split(",")
        .map((part) => part.trim());
      return {
        beforeComma,
        afterComma,
      };
    },
    truncatedContent() {
      // Returns the first 400 characters with ellipsis if content is truncated
      return this.more.substring(0, 1500) + "...";
    },
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
@import url("/src/components/PopularComponent.scss");
</style>
<style module>

#more{
  border: 1px solid #ECECEC !important;
  padding: 3%;
  border-radius: 10px;
   text-align: left;
  height: auto;
  overflow: auto;
  transition: height 0.3s ease;
}

#more.expanded {
  height: auto;
  overflow: visible;
}
#more > h1 {
  font-size: 22px;
  font-family: var(--font-family-quicksand);
  font-weight: 700;
  letter-spacing: -1px;
 
}
#more > h2 {
  font-size: 22px;
  font-family: var(--font-family-quicksand);
  font-weight: 800;
}
#more > h3 {
  font-size: 18px;
   font-family: var(--font-family-quicksand);
  font-weight: 800;
}

#more > span {
  font-family: var(--font-family-quicksand);
}
#more > ul > p > span {
  font-family: var(--font-family-quicksand);
}

#more > ul > li > h4 {
  font-size: 16px;
    font-family: var(--font-family-quicksand);
  font-weight: 800;
}
#more > ol > li > h4 {
  font-size: 16px;
   font-family: var(--font-family-quicksand);
  font-weight: 800;
}

#more > ul > li > h4 a {
  color: black;
}
#more > ol > li > h4 a {
  color: black;
}

#more > p {
  font-size: 13px;
  font-family: var(--font-family-quicksand);
  text-align: justify !important;
  font-weight: 600;
  color: var(--black);
}
#more > ol > li {
  font-size: 14px;
  font-family: var(--font-family-quicksand);
  padding-bottom: 2%;
}
#more > ol > li > span {
  font-size: 14px;
  font-family: var(--font-family-quicksand);
  font-weight: 600;
}
#more > ul > li {
  font-size: 14px;
  padding-bottom: 2%;
  font-family: var(--font-family-quicksand);
}
#more > ul > li > span {
  font-size: 14px;
  font-family: var(--font-family-quicksand);
  font-weight: 600;
}
#more > ul > li:nth-child(5) {
  padding-bottom: 0%;
}
</style>
