<template>
 <section>
 <section class="banner-section">
  <div class="wrapper">
    <div class="col-12 vayal-head">
      <p class="vayal-small-content">Empowering Farmers, Connecting Markets</p>
      <h2>
        Buy & Sell Best in Agriculture
      </h2>
      <p class="agri-para">Crops | Livestock | Farm Equipment Tractors | Check Daily Prices for Vegetables Flowers</p>
    </div>
  </div> 
 </section>
  <section
  :class="['search-section', { 'popup-search': isPopupVisible }]"
  
>
  <div class="wrapper">
    <div :class="['search-seion', { 'popup-sech': isPopupVisible }]" @click="openSearch">
       <h5>Lorem Ipsum is simply dummy text of the printing</h5>
   </div>
     <div :class="[ 'close-search',{'close-icon': isPopupVisible }]" @click="closePopup">
      <i class="fa-solid fa-xmark" style="color: #ffffff;"></i>
     </div>
    <div :class="['search-seion1', { 'popup-sech1': isPopupVisible }]" @click="openSearch">
       <h5>Pick Your Product from your nearby location?</h5>
   </div>

    <div :class="['col-lg-12 search-bar-banner', { 'popup-search-bar': isPopupVisible }]" @click="openSearch">
      
      <div class="row">
        <div class="col-lg-4 col-md-4 location">
          <img src="../assets/images/banner-location.svg" alt="" class="banner-loction-icon" />
          &nbsp;
          <md-autocomplete
            class="tamilnadu-filed"
            v-model="location"
            :md-options="searchResults"
            @input="updateSearchResults"
            @select="selectSearchResult(result, 'location')"
            md-layout="box"
            @keyup.native="openSearch"
  @focus="openSearch"
          >
            <label>Tamilnadu</label>
          </md-autocomplete>
        </div>
        <div class="col-lg-6 col-md-6 location">
          <form class="col-lg-12 col-md-12">
            <img src="../assets/images/search-icon.svg" alt="" class="location-icon"/>
            &nbsp;
            <md-autocomplete
              class="product-filed col-lg-12 col-md-12"
              v-model="product"
              :md-options="productSearchResults"
              md-clearable
              @input="updateProductSearchResults"
              @select="selectSearchResult"
                @keyup.native="openSearch"
  @focus="openSearch"
              md-layout="box"
            >
              <label>Search Your Agriculture Product & Equipments</label>
            </md-autocomplete>
          </form>
        </div>
        <div class="col-lg-2 col-md-2">
          <div class="search-btn">
          <button @click.stop="submitSearch" :disabled="product.trim() === ''">Search</button>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
        <div :class="['col-lg-12 promoted-sec ', { 'promoted': isPopupVisible }]">
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Ghee</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Palkova</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Panner</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Potato</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Sweet Potato</p>
      
        </div>
      </div>
  </div>
</section>

</section>
</template>

<script>
import { useUserStore } from "@/stores/userStore";
import { en, ta } from "../translations";

export default {
  name: "app",
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      isPopupVisible: false,
      location: "",
      product: "",
      searchResults: [],
      productSearchResults: [],
      isNameSelected: false,
      isLocSelected: false,
      defaultLocationEn: "",
      defaultLocationTa: "",
      defaultProductEn: "",
      defaultProductTa: ""
    };
  },
  created() {
    if (this.$route.params.market_name) {
      this.location = this.$route.params.market_name;
    }
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
    }
    this.isNameSelected = false;
    this.isLocSelected = false;
  },
 computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
     openSearch() {
      this.isPopupVisible = true;
    },
    closePopup(event) {
    event.stopPropagation();
    this.isPopupVisible = false;
  },
  
    onClickOutside(event) {
      if (
        event.target.id !== "example-search-input4" &&
        !event.target.classList.contains("search-results") &&
        event.target.id !== "inputCity" &&
        !event.target.classList.contains("search-results-city")
      ) {
        this.searchResults = [];
        this.productSearchResults = [];
      }
    },
    setSearchParamsFromRoute() {
      if (this.$route.params.market_name) {
        this.location = this.$route.params.market_name.replace(/-/g, " ");
        this.defaultLocationEn = this.location;
      } else {
        this.defaultLocationEn = '',
        this.location = ''
      }
      console.log("*#&#*#&#&", this.defaultLocationEn);
      
    },
  
    clearLocation() {
      this.location = "";
      this.defaultLocationEn = "";
      this.searchResults = [];
    },
    onDocumentClick(event) {
      if (
        this.$refs.locationInputContainer &&
        !this.$refs.locationInputContainer.contains(event.target)
      ) {
        this.searchResults = [];
      }
      if (
        this.$refs.productInputContainer &&
        !this.$refs.productInputContainer.contains(event.target)
      ) {
        this.productSearchResults = [];
      }
    },
     async updateSearchResults() {
  this.filterNumbers();
  try {
    this.defaultLocationEn = this.location;
    const response = await fetch(
      `https://vaiyal-app.herokuapp.com/get/location?search=${this.location}`
    );
    const data = await response.json();
    if (data.status === "success") {
      this.searchResults = data.data
        .filter(item => item.market_name !== undefined) 
        .map(item => item.market_name); 
      console.log("data-check", this.searchResults);
    } else {
      console.error("Error fetching search results:", data.msg);
    }
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
}
,
     filterNumbers() {
      this.location = this.location.replace(/[0-9]/g, '');
    },
    async updateProductSearchResults() {
      this.filterOutNumbers();
      try {
        this.productSearchResults = []
        // console.log('updateProductSearchResults')
        this.defaultProductEn = this.product;
        const response = await fetch(
         `https://vaiyal-app.herokuapp.com/get/lists?search=${this.product}`
        );
        const data = await response.json();
        if (data.status === "success") {
          this.productSearchResults = data.data.filter(item => item.sub_category_name !== undefined) 
        .map(item => item.sub_category_name);
              console.log("data-check1", this.productSearchResults);

        } else {
          console.error("Error fetching product search results:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching product search results:", error);
      }
    },
    filterOutNumbers() {
      this.product = this.product.replace(/[0-9]/g, '');
    },
    onSearchResultsClick() {
      this.isNameSelected = false;
      this.isLocSelected = false;
    },
   selectSearchResult(result,field) {
      if (field === "location") {
        this.location = this.selectedLanguage === "en" ? result.market_name : result.tn_name;
    this.defaultLocationEn = result.market_name;
    this.defaultLocationTa = result.tn_name;
    this.marketId = result.market_id;
    this.isLocSelected = true;
    this.searchResults = [];
    this.$emit("updateMarketId", this.marketId);
      } else if (field === "product") {
        this.isNameSelected = true;
        this.productSearchResults = [];
      }
    }
,
updateMarketId() {
  const userStore = useUserStore()
  userStore.updateMarketId(this.marketId);
},
    submitSearch() {
   if (
        
        (this.defaultLocationEn.trim() === "" &&
          this.defaultProductEn.trim() === "")
      ) {
        alert(
          "Please select the correct location or product before searching."
        );
        return;
      }
      this.updateMarketId();
      this.$route.params.id = null;
      this.$route.params.category = 'agriculture-products'
      let params = { ...this.$route.params,
        location: (this.defaultLocationEn && this.defaultLocationEn.trim()) ? this.defaultLocationEn : "tamilnadu",
        product : (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-'): ''
        };
        params.market_name = params.location
      params.product ? params.product : (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-') : '';
      console.log('params in newbanner.vue:', params)
      const name = params.product || this.$route.name == 'DetailComponent'  ? 'ListComponent' : this.$route.params.name

      this.$router.push({
        name: name,
        params: params,
      });
}


  },
  watch: {
    
    selectedLanguage(newLang) {
      if (newLang === "en") {
        this.location = this.defaultLocationEn;
        this.product = this.defaultProductEn;
      } else if (newLang === "ta") {
        this.location = this.defaultLocationTa;
        this.product = this.defaultProductTa;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.onDocumentClick);
    document.addEventListener("click", this.onClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
    document.removeEventListener("click", this.onClickOutside);
  }
};
</script>

<style>
@import url("/src/components/Banner.scss");
</style>
