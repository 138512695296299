<template>
   <div>
      <BannerComponent :selectedLanguage="selectedLanguage"/>
    <AdsComponent :selectedLanguage="selectedLanguage"/>
    <CategoryComponent :selectedLanguage="selectedLanguage"/>
    <ProductComponent :selectedLanguage="selectedLanguage"/>
    <EquipmentComponent :selectedLanguage="selectedLanguage"/>
    <LiveComponent :selectedLanguage="selectedLanguage"/>
    <PopularComponent :selectedLanguage="selectedLanguage"/>
    <DailyComponent :selectedLanguage="selectedLanguage"/>
    <ReviewComponent :selectedLanguage="selectedLanguage"/>
    <AppComponent :selectedLanguage="selectedLanguage"/>
    <FrequentlyComponent :selectedLanguage="selectedLanguage"/>
   </div>
</template>

<script>
import { en, ta } from "../translations.js";

import AdsComponent from '../components/AdsComponent.vue';
import AppComponent from '../components/AppComponent.vue';
import BannerComponent from '../components/BannerComponent.vue';
import CategoryComponent from '../components/CategoryComponent.vue';
import DailyComponent from '../components/DailyComponent.vue';
import EquipmentComponent from '../components/EquipmentComponent.vue';
import ReviewComponent from '../components/ReviewComponent.vue';
import LiveComponent from '../components/LiveComponent.vue';
import PopularComponent from '../components/PopularComponent.vue';
import ProductComponent from '../components/ProductComponent.vue';
import FrequentlyComponent from '../components/FrequentlyComponent.vue';
export default {
 name: "HomePage",
  props: ["selectedLanguage"],
    components: {
    BannerComponent,
    AdsComponent,
    CategoryComponent,
    ProductComponent,
    EquipmentComponent,
    LiveComponent,
    PopularComponent,
    DailyComponent,
    AppComponent,
    FrequentlyComponent,
    ReviewComponent,
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
    
  },
}
</script>

<style>

</style>