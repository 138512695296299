<template>
   <section class="aboutus-section category-page-section">
      <div class="wrapper">
         <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2 >About the VayalAgro</h2>
                <div class="address-section">
                  <img src="../assets/images/category-page-home-icon.svg" alt="" />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="">About us</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img src="../assets/images/darlingg.jpg" alt="" class="ads-img" />
            </div>
          </div>
        </div>
      </div>
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-lg-5 col-md-3 col-sm-12">
                <div class="aboutus-left-heading-div">
                    <h3>Welcome to VayalAgro</h3>
                    <h1>Providing The Finest 
                             Products To The Best 
                     Feed Suppliers.</h1>
                </div>
            </div>
            <div class="col-lg-7 col-md-9 col-sm-12">
                <div class="aboutus-right-heading-div">
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacus odio, egestas vitae augue sed, vulputate viverra velit. Quisque fringilla viverra turpis, at condimentum arcu convallis sit amet. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce laoreet lectus in velit euismod.</p>
                   <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce laoreet lectus in velit euismod. Praesent fermentum dignissim sapien ornare sagittis. Cras erat lorem, vulputate non magna ac, molestie bibendum felis.</p>
                </div>
            </div>
          </div>  
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="aboutus-main-content-div">
                    <div class="about-us-img-div">
                       <img src="../assets/images/Aboutus-component/aboutus-image-1.png" alt="">
                </div>
                <h3>Our Marketplace</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <button>View More</button>
                </div>           
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="aboutus-main-content-div">
                    <div class="about-us-img-div">
                        <img src="../assets/images/Aboutus-component/aboutus-image-2.png" alt="">
                </div>
                <h3>Unique Data Insights</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <button>View More</button>
                </div>           
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="aboutus-main-content-div">
                    <div class="about-us-img-div">
                        <img src="../assets/images/Aboutus-component/aboutus-image-3.png" alt="">
                </div>
                <h3>Join Our Community</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <button>View More</button>
                </div>           
            </div>
            <div class="bottom-line"></div>
          </div>   
          <div class="row">
            <div class="subcategory-contactus-div">
                <div class="left-subcategory-contactus-div">
                <h4>We are confident that we are the 
                    leading Company in providing agricultural products at Best Price.</h4>
             </div>
             <div class="right-subcategory-contactus-div">
                <div class="agricultural-div">
                    <div class="contactus-icon-div">
                    <img src="../assets/images/Aboutus-component/vegetable.svg" alt="">
                </div>
                <div class="right-subcategory-contactus-content">
                    <h1>1300+<span>Agricultural Products</span></h1>
                </div>
                </div>
                <div class="agricultural-div">
                    <div class="contactus-icon-div">
                    <img src="../assets/images/Aboutus-component/smile.svg" alt="">
                </div>
                <div class="right-subcategory-contactus-content">
                    <h1>1200+<span>Trust By Buyers</span></h1>
                </div>
                </div>            
             </div>
            </div>
          </div>
          <div class="row">

          </div>
        </div>
      </div>
      <div class="our-works-div">
          <div class="row">  
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="ourworks-content-div ourwork-first-div">
                        <h3>Our Mission</h3>
                        <p>At VayalAgro, we specialize in agricultural classifieds, allowing users to post items ranging from equipment and seeds to livestock and fresh produce. Our user-friendly platform enables farmers to connect directly with customers, eliminating middle agents.</p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="ourworks-content-div">
                        <h3>Our Vision</h3>
                        <p>At VayalAgro, we specialize in agricultural classifieds, allowing users to post items ranging from equipment and seeds to livestock and fresh produce. Our user-friendly platform enables farmers to connect directly with customers, eliminating middle agents.</p>
                    </div>
                </div>
            </div>
          </div>
           <div class="category-component">
            <CategoryComponent />
          </div>
    </section>
    
</template>

<script>
import CategoryComponent from '../components/CategoryComponent.vue';

export default {
     name: "AboutUsPage",
     components: {
    CategoryComponent
}
}


</script>

<style>
@import url("/src/components/AboutUsPage.scss");
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/CategoryComponent.scss");
</style>