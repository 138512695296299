<template>
    <section class="product-category-section">
      <div class="wrapper">
        <div class="container">
          <h3 class="category-title">Live Stocks</h3>
        </div>
        <div class="col-lg-12">
          <div v-if="isLoading" class="loading">
            <p>Loading...</p>
          </div>
          <div v-else>
            <div v-if="category_data.length === 0" class="no-data">
              <p>No data found.</p>
            </div>
            <div v-else class="row">
              <div v-for="(item) in displayedProducts" :key="item.id" class="col-lg-2 col-md-3 col-6">
                <div class="product-item">
                  <router-link
                :to="
                  item.hasSubCategory
                    ? {
                        name: 'SubcategoryPage',
               
                        params: {
                          categoryId: item.category_id,
                          categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                          category:
                            item.category_type === 'Live stocks'
                              ? 'livestocks'
                              : item.category_type
                                  .replace(/\s/g, '-')
                                  .replace('farm', 'agriculture'),
                                  location: 'tamilnadu',
                        },
                      }
                    : {
                        name: 'ListComponent',
                        params: {
                          product: item.name.replace(/\s/g, '-').toLowerCase(),
                          location: 'tamilnadu',
                          category:
                            item.category_type === 'Live stocks'
                              ? 'livestocks'
                              : item.category_type
                                  .replace(/\s/g, '-')
                                  .replace('farm', 'agriculture'),
                        },
                      }
                "
                :title="`Category: ${item.name}`"
              >
                  <div class="img-div">
                    <img :src="item.image" alt="Product Image">
                  </div>
                  <p>{{ item.name }}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showToggleButton" class="toggle-button">
          <button @click="toggleShowMore">
            {{ isAllProductsShown ? "Show Less" : "Show More" }}
          </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "LiveStockComponent",
    data() {
      return {
        payload: {
          categorytype: "live stocks",
        },
        category_data: [],
        initialBatchCount: 6, 
        additionalBatchCount: 6,
        currentBatchMobile: 1, 
        currentBatchDesktop: 1, 
        isMobileView: window.innerWidth <= 600, 
        isLoading: true,
      };
    },
    computed: {
      displayedProducts() {
        if (this.isMobileView) {
          const totalToShow = this.currentBatchMobile * this.additionalBatchCount;
          return this.category_data.slice(0, totalToShow);
        }
        return this.category_data.slice(0, this.currentBatchDesktop * 12);
      },
      showToggleButton() {
        if (this.isMobileView) {
          return this.category_data.length > this.initialBatchCount;
        }
        return this.category_data.length > 12;
      },
      isAllProductsShown() {
        if (this.isMobileView) {
          return this.currentBatchMobile * this.additionalBatchCount >= this.category_data.length;
        }
        return this.currentBatchDesktop * 12 >= this.category_data.length;
      },
    },
    methods: {
      async sendData() {
        this.isLoading = true; 
        try {
          const response = await axios.post(
            "https://vaiyal-app.herokuapp.com/categorytype",
            this.payload
          );
          const datas = response.data?.data || [];
          this.category_data = datas.map((item) => ({
             id: item.category_id || "N/A",
          image: item.images[0] || "N/A",
          name: item.category_name || "No Name",
          hasSubCategory: item.hasSubCategory || false,
          category_name: item.category_name,
          category_type: item.category_type
          }));
        } catch (error) {
          console.error("Error during POST request:", error);
          this.category_data = [];
        } finally {
          this.isLoading = false;
        }
      },
      toggleShowMore() {
        if (this.isMobileView) {
          if (this.isAllProductsShown) {
            this.currentBatchMobile = 1;
          } else {
            this.currentBatchMobile++;
          }
        } else {
          if (this.isAllProductsShown) {
            this.currentBatchDesktop = 1;
          } else {
            this.currentBatchDesktop++;
          }
        }
      },
      handleResize() {
        this.isMobileView = window.innerWidth <= 600;
      },
    },
    mounted() {
      this.sendData();
      this.handleResize();
      window.addEventListener("resize", this.handleResize); 
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
  };
  </script>
  
  <style scoped>
  @import url("/src/components/ProductComponent.scss");
  </style>