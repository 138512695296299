<template>
    <section>
        <section class="footer-section">
            <div class="wrapper">
                <div class="row">
                    <div class="col-lg-4 col-md-4 first-sec">
                        <div class="foot-logo">
                            <img src="../assets/images/vayal-new-logo.png" alt="Vayal logo" class="logo" loading="lazy">
                            <h3 class="brand-name">VAYAL<span>AGRO</span>&#8482;</h3>
                        </div>
                        <p class="foot-desc">VayalAgro - Agriculture Classifieds, Buy, Sell, Daily & History Market
                            Prices,updates and news</p>
                        <div class="social-icons">
                            <a href="#"><img src="../assets/images/footer-component/whatsapp-footer.svg" alt="whatsapp"></a>
                            <a href="#"><img src="../assets/images/footer-component/instagram-footer.svg" alt="instagram"></a>
                        </div>
                        <p class="download">Download App</p>
                        <div class="foot-logo d-flex align-items-center">
                            <img src="../assets/images/googleplay.svg" alt="get play" class="play-logo">
                        </div>
                        <p class="copy">Copyright &copy; <span id="year"></span> Vayal. All Rights Reserved.</p>

                    </div>
                    <!-- <div class="col-lg-1"></div> -->
                    <div class="col-lg-4 col-md-4">
                        <ul class="quick-links">
                            <li><a href="/">Home</a></li>
                            <li><a>Blogs</a></li>
                            <li><a href="/marketprice">Market Price</a></li>
                            <li><router-link to="/about">About</router-link></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                            <li><a>Terms Of Use</a></li>
                            <li><a>Responsible Disclosure Policy</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <ul class="contact-info">
                            <div class="address-section"> 
                                <img src="../assets/images/footer-component/icon.svg" alt="">
                                <p class="footer-add">M/S. Vayal Agro Tech (Opc) Pvt Ltd, 28 Ncn Garden , Thirunagar (Opp to
                                Muthu Mahal ) , Gobichettipalayam, Erode , Tamil Nadu.</p>
                            </div>
                            <div class="address-section"> 
                                <img src="../assets/images/footer-component/Mail-icon.svg" alt="">
                                <li>support@vayalagro.com</li>
                            </div>
                            
                            <div class="foot-bot-img">
                                <img src="../assets/images/footer-component/Group.png" loading="lazy">
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    name: 'FooterComponent',
    mounted() {
        document.getElementById('year').textContent = new Date().getFullYear();
    },
};
</script>

<style>
@import url("/src/components/FooterComponent.scss");
</style>