<template>
  <section class="popular-category-section daily-section">
    <div class="wrapper">
      <div class="main">
        <div class="categories_head ">
          <h1>Daily Best Sells</h1>
        </div>
        <div class="categories">
          <!-- Tabs -->
          <div>
            <p
              @click="selectTab('newlyAdded')"
              :style="selectedTab === 'newlyAdded' ? activeStyle : defaultStyle"
            >
              New Added
            </p>
          </div>
          <div>
            <p
              @click="selectTab('recommended')"
              :style="selectedTab === 'recommended' ? activeStyle : defaultStyle"
            >
              Featured
            </p>
          </div>
          <!-- Carousel Controls -->
          <div class="for_btn">
            <span @click="moveLeft" :class="{ disabled: currentIndex === 0 }">
              <i class="fa-solid fa-chevron-left" style="color: #ffffff;"></i>
            </span>
            <span
              @click="moveRight"
              :class="{ disabled: currentIndex >= maxIndex }"
            >
              <i class="fa-solid fa-chevron-right" style="color: #ffffff;"></i>
            </span>
          </div>
        </div>
      </div>
      <!-- Loader -->
      <div v-if="isLoading" class="loader">
        <div class="row">
                
                  <div v-for="data  in 4" :key="data.id" class="col-lg-3 col-md-4 col-sm-12 ">
                  <p class="shimmer-line-img"></p>
                  <p class="shimmer-line"></p>
                  <p class="shimmer-line"></p>
                </div>
                 </div>
      </div>
      <!-- Product List -->
      <div class="row" v-else :style="{ transform: `translateX(-${currentIndex * (0 / itemsPerPage)}%)` }">
        <div class="col-lg-3 col-md-4 col-sm-12" v-for="data in displayedData" :key="data.id">
          <div class="product">
            <div class="img_div">
              <img
                :src="data.images[0] || require('../assets/images/No-Data-list.png')"
                alt="Product Image" loading="lazy"
              />
            </div>
            <p 
  class="type" 
  :style="{ backgroundColor: data.user_type === 'Agency' ? '#B0C800' : 'lightskyblue' }"
>
  {{ data.user_type }}
</p>

            <div class="align">
              <p class="sub_category_name">{{ data.category_name }}</p>
              <p class="product-name">{{ data.name }}  {{ data.business_name }}</p>
              <div class="date-location">
                <div>
                  <p class="loction-data">
                    <span>
                      <img
                        src="../assets/images/location-icon.svg"
                        alt="" loading="lazy"
                      /></span
                    >{{ data.place }}, {{ data.market_name }}
                  </p>
                </div>
                <div>
                  <p class="created">
                    {{
                      new Date(data.created).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })
                    }}
                  </p>
                </div>
              </div>
              <div class="price-view">
                <div>
                  <p class="price">
                    <span
                      ><i
                        class="fa-solid fa-indian-rupee-sign"
                        style="color: #018f43;"
                      ></i></span
                    >{{ data.price }}/ {{ data.quantity }}
                  </p>
                </div>
                <div>
                  <button>View More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PopularComponent",
  data() {
    return {
      currentIndex: 0, // Current position in the carousel
      itemsPerPage: 4, // Number of items visible at a time
      selectedTab: "newlyAdded", // Tracks which tab is selected
      isLoading: false, // Tracks loading state
      category_data: [], // Data for "Newly Added"
      given_data: [], // Data for "Recommended"
      activeStyle: {
        color: "#018f43",
      },
      defaultStyle: {
        color: "#000000",
        fontWeight: "normal",
        textDecoration: "none",
      },
    };
  },
  computed: {
    displayedData() {
      const currentData =
        this.selectedTab === "newlyAdded" ? this.category_data : this.given_data;

      const start = this.currentIndex * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return currentData.slice(start, end);
    },
    maxIndex() {
      const currentData =
        this.selectedTab === "newlyAdded" ? this.category_data : this.given_data;
      return Math.ceil(currentData.length / this.itemsPerPage) - 1;
    },
  },
  mounted() {
    this.fetchNewlyAdded();
  },
  methods: {
    async fetchNewlyAdded() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "https://vaiyal-app.herokuapp.com/get/recentlist?marketid=1"
        );
        this.category_data = response.data.data || [];
      } catch (error) {
        console.error("Error fetching Newly Added data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRecommended() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "https://vaiyal-app.herokuapp.com/get/recommendlist?type=equipment",
        );
        this.given_data = response.data?.data || [];
      } catch (error) {
        console.error("Error fetching Recommended data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    selectTab(tab) {
      if (this.selectedTab === tab) return;
      this.selectedTab = tab;
      this.currentIndex = 0; // Reset index when switching tabs
      if (tab === "recommended" && this.given_data.length === 0) {
        this.fetchRecommended();
      }
    },
    moveLeft() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    moveRight() {
      if (this.currentIndex < this.maxIndex) {
        this.currentIndex++;
      }
    },
  },
};
</script>

<style scoped>
@import url("/src/components/PopularComponent.scss");
</style>