var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-categorys"},[_c('div',{staticClass:"wrapper home-wrap"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 agri-border"},[_c('div',{staticClass:"agri-product",on:{"click":function($event){return _vm.getModifiedCategoryLink('agriculture products', _vm.defaultLocationEn)}}},[_vm._m(0),_c('h2',[_vm._v(_vm._s(_vm.translations.products))]),_c('p',{staticClass:"Product-description"},[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry")]),_vm._m(1)])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 agri-border"},[_c('div',{staticClass:"agri-product",on:{"click":function($event){return _vm.getModifiedCategoryLink('agriculture equipments', _vm.defaultLocationEn)}}},[_vm._m(2),_c('h2',[_vm._v("Equipments")]),_c('p',{staticClass:"Product-description"},[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry")]),_vm._m(3)])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 agri-border"},[_c('div',{staticClass:"agri-product",on:{"click":function($event){return _vm.getModifiedCategoryLink('livestocks', _vm.defaultLocationEn)}}},[_vm._m(4),_c('h2',[_vm._v("Live Stocks")]),_c('p',{staticClass:"Product-description"},[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry")]),_vm._m(5)])]),_vm._m(6)])])]),(!_vm.isAboutPage)?_c('RibbenSection'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agri-product-img"},[_c('img',{attrs:{"src":require("../assets/images/category-component/category-Product-icon.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"view-more-com"},[_vm._v("View More "),_c('span',[_c('img',{attrs:{"src":require("../assets/images/Vector (2).png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agri-product-img"},[_c('img',{attrs:{"src":require("../assets/images/category-component/jcb.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"view-more-com"},[_vm._v("View More "),_c('span',[_c('img',{attrs:{"src":require("../assets/images/Vector (2).png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agri-product-img"},[_c('img',{attrs:{"src":require("../assets/images/category-component/cow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"view-more-com"},[_vm._v("View More "),_c('span',[_c('img',{attrs:{"src":require("../assets/images/Vector (2).png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12"},[_c('div',{staticClass:"agri-product"},[_c('a',{attrs:{"href":"/marketprice"}},[_c('div',{staticClass:"agri-product-img"},[_c('img',{attrs:{"src":require("../assets/images/category-component/market.svg"),"alt":""}})]),_c('h2',[_vm._v("Market Price")]),_c('p',{staticClass:"Product-description"},[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry")]),_c('p',{staticClass:"view-more-com"},[_vm._v("View More "),_c('span',[_c('img',{attrs:{"src":require("../assets/images/Vector (2).png"),"alt":""}})])])])])])
}]

export { render, staticRenderFns }