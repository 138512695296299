var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product-category-section"},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"col-lg-12"},[(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('p',[_vm._v("Loading...")])]):_c('div',[(_vm.category_data.length === 0)?_c('div',{staticClass:"no-data"},[_c('p',[_vm._v("No data found.")])]):_c('div',{staticClass:"row"},_vm._l((_vm.displayedProducts),function(item){return _c('div',{key:item.id,staticClass:"col-lg-2 col-md-3 col-6"},[_c('div',{staticClass:"product-item"},[_c('router-link',{attrs:{"to":item.hasSubCategory
                ? {
                    name: 'SubcategoryPage',
           
                    params: {
                      categoryId: item.category_id,
                      categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                      category:
                        item.category_type === 'Live stocks'
                          ? 'livestocks'
                          : item.category_type
                              .replace(/\s/g, '-')
                              .replace('farm', 'agriculture'),
                              location: 'tamilnadu',
                    },
                  }
                : {
                    name: 'ListComponent',
                    params: {
                      product: item.name.replace(/\s/g, '-').toLowerCase(),
                      location: 'tamilnadu',
                      category:
                        item.category_type === 'Live stocks'
                          ? 'livestocks'
                          : item.category_type
                              .replace(/\s/g, '-')
                              .replace('farm', 'agriculture'),
                    },
                  },"title":`Category: ${item.name}`}},[_c('div',{staticClass:"img-div"},[_c('img',{attrs:{"src":item.image,"alt":"Product Image"}})]),_c('p',[_vm._v(_vm._s(item.name))])])],1)])}),0)])]),(_vm.showToggleButton)?_c('div',{staticClass:"toggle-button"},[_c('button',{on:{"click":_vm.toggleShowMore}},[_vm._v(" "+_vm._s(_vm.isAllProductsShown ? "Show Less" : "Show More")+" ")])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h3',{staticClass:"category-title"},[_vm._v("Live Stocks")])])
}]

export { render, staticRenderFns }