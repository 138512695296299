<template>
   <section class="ribben-section">
    <div class="wrapper">
      <div class="col-12 ribben">
         <div class="row">
             <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="bore download-count">
                    <span>13K+ Downloads</span>
                </div>
              </div>
             <div class="col-lg-4 col-md-4  col-sm-12">
                <div class="bore">
                    <span>1300+ Agricultural Products</span>
                </div>
             </div>
             <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="buyers">
                    <span >1200+ Trust By Buyers</span>
                </div>
             </div>
         </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
@import url("/src/components/RibbenComponent.scss");

</style>