<template>
  <section class="home-categorys">
      <div class="wrapper home-wrap">
         <div class="col-12">
             <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 agri-border">
                   <div class="agri-product" @click="getModifiedCategoryLink('agriculture products', defaultLocationEn)">
                      <div class="agri-product-img">
                         <img src="../assets/images/category-component/category-Product-icon.svg" alt="">              
                      </div>
                      <h2>{{translations.products}}</h2>
                      <p class="Product-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      <p class="view-more-com">View More <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                   </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 agri-border">
                    <div class="agri-product" @click="getModifiedCategoryLink('agriculture equipments', defaultLocationEn)">
                      <div class="agri-product-img">
                        <img src="../assets/images/category-component/jcb.svg" alt=""> 
                      </div>
                      <h2>Equipments</h2>
                      <p class="Product-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      <p class="view-more-com">View More <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                   </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 agri-border">
                    <div class="agri-product"  @click="getModifiedCategoryLink('livestocks', defaultLocationEn)">
                      <div class="agri-product-img">
                        <img src="../assets/images/category-component/cow.svg" alt=""> 
                      </div>
                      <h2>Live Stocks</h2>
                      <p class="Product-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      <p class="view-more-com">View More <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                   </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="agri-product">
                     <a href="/marketprice">
                      <div class="agri-product-img">
                        <img src="../assets/images/category-component/market.svg" alt=""> 
                      </div>
                      <h2>Market Price</h2>
                      <p class="Product-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      <p class="view-more-com">View More <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                      </a>
                   </div>
                </div>
             </div>
         </div>
      </div>
        <RibbenSection v-if="!isAboutPage"/>

  </section>
  
</template>

<script>
import RibbenSection from './RibbenComponent.vue';
import { en, ta } from "../translations.js";

export default {
   
components: {
   RibbenSection
  },
  
   data() {
    return {
      isPopupVisible: false,
      location: "",
      product: "",
      searchResults: [],
      productSearchResults: [],
      isNameSelected: false,
      isLocSelected: false,
      defaultLocationEn: "",
      defaultLocationTa: "",
      defaultProductEn: "",
      defaultProductTa: "",
      selectedLanguage: "en", // Default language
      translations: en,
       isAboutPage: true,
    };
  },
  
  created() {
    if (this.$route.params.market_name) {
      this.location = this.$route.params.market_name;
    }
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
    }
    this.isNameSelected = false;
    this.isLocSelected = false;
     const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
      this.translations = storedLanguage === "en" ? en : ta;
    }
     this.checkAboutPage();
  },
 computed: {
    // translations() {
    //   return this.selectedLanguage === "en" ? en : ta;
    // }
  },
  methods: {
         checkAboutPage() {
      this.isAboutPage = this.$route.name === 'AboutUsPage'
    },
  getModifiedCategoryLink(category, locationData) {
    // Validate location selection
    
    // Construct the route with default location if not provided
    const location = locationData || "tamilnadu";

    // Navigate to CategoryPage
    this.$router.push({
      name: "CategoryPage",
      params: {
        category: category.replace(/\s+/g, "-").toLowerCase(),
        location: location.toLowerCase()
      }
    });
  }
},
 mounted() {
    this.checkAboutPage(); // Check on initial load
    this.$watch('$route', this.checkAboutPage); 
  },
 watch:{
  '$route': function() {
      this.checkAboutPage();
      
    },

     selectedLanguage(newLang) {
      localStorage.setItem("selectedLanguage", newLang);

      // Update translations
      this.translations = newLang === "en" ? en : ta;

      // Clear the language after 5 minutes
      setTimeout(() => {
        localStorage.removeItem("selectedLanguage");
      }, 5 * 60 * 1000); // 5 minutes
    },
  },
   
}
</script>

<style>
@import url("/src/components/CategoryComponent.scss");

</style>