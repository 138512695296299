import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js'; // Correct router import
import { createPinia, PiniaVuePlugin } from 'pinia';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin); // Ensure Pinia is used as a Vue plugin

const pinia = createPinia();
Vue.use(VueMaterial); // Vue Material plugin

new Vue({
  router, // Pass the router to Vue instance
  pinia,
  render: (h) => h(App), // Render the App component
}).$mount('#app');
