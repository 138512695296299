<template>
   <section class="gooogle-ads-section">
      <div class="wrapper">
         <div class="col-12 advertisement">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-none">
                   <!-- google ads display -->
                    <div class="left-ads">
                       <img src="..//assets/images/dariling.png" alt="">
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                   <!-- google ads display -->
                   <div class="right-ads">
                       <img src="..//assets/images/darlingg.jpg" alt="">
                    </div>
                </div>
            </div>
         </div>
      </div>       
   </section>
</template>

<script>
export default {
  name: "AdsComponent",

}
</script>

<style>
 @import url("/src/components/Ads.scss");
</style>