export const getProductRoute = (product, route = null) => {
  console.log('product', product);
  console.log("route", route);

  let params = {
    ...route,
  };

  // Ensure 'seller' exists and provide a fallback value
  // params.seller = (product.seller?.replace(/\s+/g, '-')?.toLowerCase()) ?? "seller";

  // Ensure 'location' exists, or default to 'market_name' if available
  if (!params.location || params.location === 'tamilnadu') {
    params.location = (product.market_name?.replace(/\s+/g, '-')?.toLowerCase()) ?? "unknown-location";
  }

  console.log("route params in prod.js", params);

  if (product.product_id) {
    params.id = product.product_id;

    return {
      name: product.category_type === "live stocks"
        ? "livestockdetails"
        : "DetailComponent",
      params,
    };
  } else if (product.equip_product_id) {
    params.id = product.equip_product_id;

    return { name: 'equipmentdetail', params };
  }

  return { name: 'fallbackRoute' };
};
