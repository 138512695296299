<template>
  <section class="side-category-section">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="side-category">
                <h1 class="category-name">New Products</h1>
                <div  class="side-product-list">
                  <div class="side-product-image-div">
                    <img class="side-product-image" src="../assets/images/jack-fruit.svg" alt="Product Image" loading="lazy">
                  </div>
                  <div class="side-product-name-div">
                    <h4>Jack Fruit</h4>
                  </div>
                
                </div>
                 <div  class="side-product-list">
                  <div class="side-product-image-div">
                    <img class="side-product-image" src="../assets/images/pomegranate.svg" alt="Product Image" loading="lazy">
                  </div>
                  <div class="side-product-name-div">
                    <h4>Pomegranate</h4>
                  </div>
                
                </div>
                 <div  class="side-product-list">
                  <div class="side-product-image-div">
                    <img class="side-product-image" src="../assets/images/apple.svg" alt="Product Image" loading="lazy">
                  </div>
                  <div class="side-product-name-div">
                    <h4>Fresh!
Apple</h4>
                  </div>
                
                </div>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12" v-if="!isListPage">
                <div class="side-app">
                     <p class="try-text">Try Out Our</p>
                     <div class="header-logo">
                       <a href="" class="d-flex align-items-center">
                         <div class="app-div"><img src="../assets/images/vayal-new-logo.png" alt="" /></div>
                         <p>VAYAL<span>AGRO</span>&#8482;</p>
                       </a>
                     </div>
                     <p class="new-mbl-app">New Mobile App</p>

                     <div class="round-img">
                        <img src="../assets/images/Ellipse.svg" alt="" class="ellopse-img">
                     </div>
                     <img src="../assets/images/vertical-app.svg" alt="" class="app-use">

                     <button>Download Now</button>
                </div>
            </div>
        </div>
 </section>
</template>
<script>

export default {
  name: "NewproductComponent",
  data() {
   return {
      isListPage: true,
    };
  },
 created(){
    this.checkListPage();
  },
   watch:{
  '$route': function() {
      this.checkListPage();
      
    },
  },
  computed: {
  },
   mounted() {
    this.checkListPage(); // Check on initial load
    this.$watch('$route', this.checkListPage); 
  },
  methods: {
   checkListPage() {
      this.isListPage = this.$route.name === 'ListComponent'
    },
  },
};
</script>

<style>
@import url("/src/components/NewproductComponent.scss");
</style>