<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <HeaderComponent />
    <!-- <BannerComponent />
    <AdsComponent />
    <CategoryComponent />
    <ProductComponent />
    <EquipmentComponent />
    <LiveComponent />
    <PopularComponent />
    <DailyComponent />
    <ReviewComponent />
    <AppComponent />
    <FrequentlyComponent /> -->
    <!-- <CategoryPage /> -->
    <router-view></router-view>
    <!-- <SubcategoryPage /> -->
    <FooterComponent />
  </div>
</template>

<script>

// import AdsComponent from './components/AdsComponent.vue';
// import AppComponent from './components/AppComponent.vue';
// import BannerComponent from './components/BannerComponent.vue';
// import CategoryComponent from './components/CategoryComponent.vue';
// import DailyComponent from './components/DailyComponent.vue';
// import EquipmentComponent from './components/EquipmentComponent.vue';
// import ReviewComponent from './components/ReviewComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
// import { en, ta } from "@/translations";

// import CategoryPage from './components/CategoryPage.vue';
// import SubcategoryPage from './components/SubcategoryPage.vue';
// import LiveComponent from './components/LiveComponent.vue';
// import PopularComponent from './components/PopularComponent.vue';
// import ProductComponent from './components/ProductComponent.vue';
// import FrequentlyComponent from './components/FrequentlyComponent.vue';
export default {
  name: 'App',
   
  components: {
    HeaderComponent,
    // BannerComponent,
    // AdsComponent,
    // CategoryComponent,
    // ProductComponent,
    // EquipmentComponent,
    // LiveComponent,
    // PopularComponent,
    // DailyComponent,
    // AppComponent,
    // FrequentlyComponent,
    // ReviewComponent,
    // CategoryPage,
    
    // SubcategoryPage,
    FooterComponent
  },
  
}
</script>

<style>
@import url("./assets/scss/style.scss");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
