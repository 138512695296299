<template>
  <section class="category-page-section">
    <div class="wrapper">
          <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2  v-if="product" class="detail-head">
                  {{ product.business_name }}
                </h2>
                <div class="address-section">
                 
                  <p class="footer-add">
                    <router-link
                      :to="
                        this.$route.params.category === 'agriculture-products'
                          ? `/${this.$route.params.location}/agriculture-products`
                          : this.$route.params.category ===
                            'agriculture-equipments'
                          ? `/${this.$route.params.location}/agriculture-equipments`
                          : `/${this.$route.params.location}/livestocks`
                      "
                    >
                     {{
    this.$route.params.category === 'agriculture-products'
      ? 'Agriculture Products'
      : this.$route.params.category === 'agriculture-equipments'
      ? 'Agriculture Equipments'
      : this.$route.params.category === 'livestocks'
      ? 'Livestocks'
      : this.$route.params.category
  }}
                    </router-link>
                  </p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />

                  <p class="footer-add">
                    <router-link v-if="product.sub_category_id != '0'" :to="{ name: 'ListComponent', params: { categoryId: product.category_id, categoryName: product.category_name.replace(/\s/g, '-').toLowerCase(), category: 'agriculture-equipments', location: this.$route.params.location } }">
              {{ product.category_name  }}
            </router-link>
                  </p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />

                  <p class="footer-add">
                    <router-link @click="navigateToAllList" to="">
  {{ (product.sub_category_name.trim() !== '' ? product.sub_category_name : product.category_name) }}
</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img src="../assets/images/darlingg.jpg" alt="" class="ads-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12  mt-5">
        <div class="row just-align">
          <div class="col-lg-3 col-md-3 col-sm-12 order-2 order-lg-1" >
            <SidebarComponent />
          </div>
          <div class="col-lg-9 col-md-9 col-sm-12 order-1 order-lg-2">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="image-section">
                  <div class="product-detail-image" >
                    <img
                    class="product-image"
                  v-if="product.images && product.images.length > 0"
                  :src="product.images[selectedImageIndex]"
                  :alt="`Proudct: ${product.name}`"
                  @click="openImage"
                  @error="handleGalleryImageError"
                />
                <img
                  class="p-0 m-0"
                  v-else
                  src="../assets/images/No-Data-list.png"
                  :alt="`Proudct: ${product.name}`"
                />
                  </div>
                  <div class="slef-image-div">
                    <div
                      class="product-detail-sub-image"
                      v-for="(image, index) in product.images"
                    :key="index"
                    @click="selectImage(index)"
                    >
                      <img
                        class="product-image"
                        :src="image"
                      loading="lazy"
                      :alt="`Proudct: ${product.name}`"
                      @error="handleThumbnailError"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="information-section">
                  <p v-if="product.user_type !== undefined && product.user_type !== ''"  class="detail-type">{{ product.user_type }}</p>
                  <p v-else class="detail-type"> Not mentioned</p>
                  <h3 class="product-name">{{ product.business_name }}</h3>
                  <p class="category-type">Category: {{ product.category_type }}</p>
                  <h2 class="product-price" v-if="product.price !== '0' && product.price !== '00' && product.price !== ''">₹{{ product.price }}/{{ product.quantity_type }}</h2>
                  <h2 class="product-price-not" v-else>Price Not Mentioned</h2>

                  <div class="contact-info-div">
                    <div>
                      <img
                        src="../assets/images/detail-component/profile.svg"
                        alt="profile"
                      />
                    </div>
                    <div class="contact-info">
                      <p v-if="product.sellername">{{ product.sellername }}</p>
                      <p v-else>Not Mentioned</p>
                      <p>+91 {{ product.phone_number }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-3" v-if="product.delivery">
                      <div class="delivery-div" >
                      <p class="delivery">
                        <img src="../assets/images/detail-component/delivery.svg" alt="" />
                        Delivery Available
                      </p>
                    </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-3">
                      <div class="report-div">
                        <p class="report">
                          <img src="../assets/images/detail-component/report.svg" alt="" /> Report Issue
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="item-info-div">
                    <div class="info-img-div">
                      <img
                        src="../assets/images/detail-component/info.svg"
                        alt="profile"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <p>Item Id</p>
                      <p class="info">{{ product.uniqueId }}</p>
                    </div>
                    <div class="info-img-div">
                      <img
                        src="../assets/images/detail-component/info.svg"
                        alt="profile"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <p>Item Created</p>
                      <p class="info">{{ formatDate(product.created) }}</p>
                    </div>
                  </div>
                  <div v-if="product.address && Object.keys(product.address).length > 0" class="address-div" >
                    <div class="address-img-div">
                      <img
                        src="../assets/images/detail-component/location.svg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p>
                      {{ product.address.doorNo }} {{ product.address.areaStreet }}
                      {{ product.address.pincode }} {{ product.address.townCity }}
                      {{ product.address.landmark }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ">
            <div class="col-lg-12">
              <div class="review-div">
    <div class="review-heading-div">
      <p
        class="review-heading"
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === tab.name }"
        @click="setActiveTab(tab.name)"
      >
        {{ tab.label }}
      </p>
    </div>
    <div class="review-content-div">
      <p class="content" v-if="activeTab === 'description'">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit nemo
        adipisci similique porro asperiores placeat dolorum ipsam quidem
        quibusdam officia iste tenetur, pariatur obcaecati vel?
      </p>
      <p class="content" v-if="activeTab === 'additionalInfo'">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita
        possimus tenetur consequatur fugiat pariatur, temporibus eos quae optio
        debitis velit dignissimos nisi dolore veritatis voluptatem.
      </p>
      <p class="content" v-if="activeTab === 'vendor'">
        Vendor Information: This is where vendor details will be displayed.
      </p>
      <p class="content" v-if="activeTab === 'reviews'">
        Reviews: Users' reviews will be displayed here.
      </p>
    </div>
  </div>
            </div>
          </div>

          </div>
          
        </div>
      </div>
            <div class="col-lg-12 col-md-12 col-sm-12  mt-5">
            </div>
    </div>
    <!-- Full-Screen Image Modal -->
      <div>
          <div v-if="showImage" class="modal">
            <div class="modal-content">
              <button class="close-button" @click="closeImage">
                <i class="fa-solid fa-x"></i>
              </button>
              <div class="showimg">
                <img
                  v-if="selectedImageIndex !== null"
                  :src="product.images[selectedImageIndex]"
                  alt="Selected Product Image"
                  class="selected-image"
                />
                <button
                  v-if="selectedImageIndex > 0"
                  @click="changeImage(-1)"
                  class="prev-button"
                >
                  &#10094;
                </button>
                <button
                  v-if="selectedImageIndex < product.images.length - 1"
                  @click="changeImage(1)"
                  class="next-button"
                >
                  &#10095;
                </button>
              </div>
            </div>
          </div>
        </div>
  </section>
</template>

<script>
import axios from "axios";

import SidebarComponent from "./SidebarComponent.vue";
import { en, ta } from "@/translations";
import { getProductRoute } from "./product";
export default {
  props: ["selectedLanguage"], // Receive the selectedLanguage prop from App.vue
  components: {
    // BottomAd
    SidebarComponent
  },
  data() {
    return {
      productList: [],
      currentPage: 1,
      product: null,
      isLoading: true, // Set isLoading to true
      isImagePopupOpen: false,
      selectedImageIndex: 0, // Index of the clicked image
      showForm: false,
      showImage: false,
      showDeliveryMessage: false,
      formData: {
        name: "",
        selectedOption: null,
      },
      reportTypes: [],
      selectedReportType: null,
      activeTab: "description", // Default active tab
      tabs: [
        { name: "description", label: "Description" },
        { name: "additionalInfo", label: "Additional Info" },
        { name: "vendor", label: "Vendor" },
        { name: "reviews", label: "Reviews (3)" },
      ],
    };
  },

  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  watch: {
    "$route.params.id": function (newId, oldId) {
      // This function will be called whenever the 'id' parameter in the URL changes
      console.log("ID changed. New ID:", newId, "Old ID:", oldId);

      // You can perform actions or fetch data based on the new ID here
      this.fetchProductDetails(newId);
      this.productList = [];
      this.fetchData();
    },
     showImage(newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted() {
    // Fetch data when the component is mounted
    this.fetchDatas();
  },
  methods: {
      setActiveTab(tabName) {
      this.activeTab = tabName;
    },
    changeImage(direction) {
      const newIndex = this.selectedImageIndex + direction;
      if (newIndex >= 0 && newIndex < this.product.images.length) {
        this.selectedImageIndex = newIndex;
      }
    },
    getProductRoute: getProductRoute,
    navigateToAllList() {
      delete this.$route.params.id;
      this.$router.push({
        name: "LiveComponent",
        params: {
          ...this.$route.params,
        },
      });
    },

    fetchDatas() {
      // Fetch data from the API endpoint
      fetch("https://vaiyal-app.herokuapp.com/reporttypes")
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            this.reportTypes = data.data;
          } else {
            console.error("Failed to fetch data:", data.msg);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    },
    getProductImage(product) {
      if (
        product.images &&
        product.images.length > 0 &&
        typeof product.images[0] === "string" &&
        product.images[0].trim() !== ""
      ) {
        return product.images[0];
      } else {
        return require("../assets/images/No-Data-list.png");
      }
    },
    handleImageError(event) {
      event.target.src = require("../assets/images/No-Data-list.png");
    },
    handleGalleryImageError(event) {
      event.target.src = require("../assets/images/No-Data-list.png");
    },
    handleThumbnailError(event) {
      event.target.src = require("../assets/images/No-Data-list.png");
    },
    openForm() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
    },
    openImage() {
      this.showImage = true;
    },
    closeImage() {
      this.showImage = false;
    },
    submitForm() {
      const selectedReportType = this.selectedReportType || "0"; // Use "0" if no radio option is selected

      // Extract relevant information from the product data
      const productId = this.product.equip_product_id;
      const categoryid = this.product.category_id;
      const subcategoryid = this.product.sub_category_id || "0";

      // Create the payload for the API request
      const requestBody = {
        created: this.formatDate(new Date()), // Assuming you want the current date
        type: "equipments",
        id: productId,
        categoryid: categoryid,
        subcategoryid: subcategoryid,
        userid: "0",
        report_type_id: selectedReportType,
        others: this.formData.additionalDetails,
      };
      // console.log(requestBody);
      // Include the additional details if provided
      if (this.formData.name) {
        requestBody.additionalDetails = this.formData.name;
      }

      // Send a POST request to the API endpoint
      axios
        .post("https://vaiyal-app.herokuapp.com/createreports", requestBody)
        .then((response) => {
          console.log("Report submitted successfully:", response.data);
          // Display a success message
          this.showSuccessMessage();
          // Close the form after successful submission
          this.showForm = false;
          // Reset the form data
          this.formData = {};

          // Reset the form data
          this.selectedReportType = null; // Reset the selected radio option
          this.formData.additionalDetails = ""; // Reset the textarea
          // If you have other form fields, reset them here
        })
        .catch((error) => {
          console.error("Error submitting report:", error);
        });
    },
    async fetchData() {
      try {
        const product = this.$route.params.product.replace(/-/g, " ");
        const response = await axios.post(
          `https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`
          // ` https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`
        );

        // Similar products
        response.data.data.map((item) => {
          if (item?.equip_product_id !== this.product?.equip_product_id)
            this.productList.push(item);
        });
        console.log("product list:", this.productList);
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    },
    showSuccessMessage() {
      // You can customize the success message display according to your UI/UX
      alert("Report submitted successfully!");
      // Alternatively, you can set a property to control the display in your template
      // this.successMessage = "Report submitted successfully!";
    },
    truncatetitle(name) {
      if (name.length > 22) {
        return name.substring(0, 18) + "...";
      }
      return name;
    },
    goBack() {
      this.$router.go(-1);
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
//      getProductRoute(product) {
//       if (product.product_id) {
//         return { name: 'DetailComponent', params: { id: product.product_id, product: product.sub_category_name.replace(/\s+/g, '-'),        productName: product.name.replace(/\s+/g, '-')
//  } };
//       } else if (product.equip_product_id) {
//         return { name: 'EquipDetail', params: { id: product.equip_product_id, product: product.sub_category_name.replace(/\s+/g, '-'),        businessName: product.business_name.replace(/\s+/g, '-')
//  } };
//       }
//       // Handle the case where neither product_id nor equip_product_id is available.
//       return { name: 'fallbackRoute' };
//     },
    formatDate(date) {
      const dateObject = new Date(date);
      const day = String(dateObject.getDate()).padStart(2, "0");
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const year = dateObject.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async fetchProductDetails(productId) {
      try {
        // const response = await axios.get(`https://vaiyal-app.herokuapp.com/product/${productId}`);
        const response = await axios.get(`https://vaiyal-app.herokuapp.com/equipproduct/${productId}`);
        this.product = response.data.data;
        console.log("equipmwnt", this.product);
         const equipUnique = this.product.equip_product_id; 
          if (!equipUnique || equipUnique === "") {
      this.$router.push({ name: "Error" });
      return; // Prevent further code execution
    }
        this.subCategoryName = this.product?.sub_category_name; // Extract sub-category name
const apiCategoryName = this.product.category_name;
    const currentCategoryName = this.$route.params.categoryName;

    const apiCategoryType = "agriculture-equipments";
    const currentCategoryType = 
      this.$route.params.category === 'agriculture-products' ? 'Agriculture Products' :
      this.$route.params.category === 'agriculture-equipments' ? 'Agriculture Equipments' :
      this.$route.params.category === 'live stocks' ? 'Livestocks' :
      this.$route.params.category; 

    const apiSubcategoryName = this.product.sub_category_name;
    const currentSubcategoryName = this.$route.params.product;

    const apiLocation = this.product.market_name;
    const currentLocation = this.$route.params.location;

    const apiSeller = this.product.seller;
    const currentSeller = this.$route.params.seller;
console.log("apiSeller",apiSeller);
    let needsRouteUpdate = false;
    let newRouteParams = { ...this.$route.params }; 

    if (apiCategoryName && apiCategoryName !== currentCategoryName) {
      newRouteParams.categoryName = apiCategoryName.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }

    if (apiCategoryType && apiCategoryType !== currentCategoryType) {
      newRouteParams.category = apiCategoryType === "live stocks" ? "livestocks" : apiCategoryType.replace("farm", "agriculture").replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    if (apiSubcategoryName && apiSubcategoryName !== currentSubcategoryName) {
      newRouteParams.product = apiSubcategoryName.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    if (apiLocation && apiLocation !== currentLocation) {
      newRouteParams.location = apiLocation.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    if (apiSeller && apiSeller !== currentSeller) {
      newRouteParams.seller = apiSeller.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    // If any update is needed, replace the current route params
    if (needsRouteUpdate) {
      this.$router.replace({ ...this.$route, params: newRouteParams });
    }

        console.log("Product response:", this.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
      this.isLoading = false;
    },
  },
  created() {
    this.fetchData();

    const productId = this.$route.params.id;
    this.fetchProductDetails(productId);
  },
};
</script>




<style>
@import url("/src/components/DetailComponent.scss");
@import url("/src/components/CategoryPage.scss");
</style>