import { render, staticRenderFns } from "./SubcategoryPage.vue?vue&type=template&id=59de6456"
import script from "./SubcategoryPage.vue?vue&type=script&lang=js"
export * from "./SubcategoryPage.vue?vue&type=script&lang=js"
import style0 from "./SubcategoryPage.vue?vue&type=style&index=0&id=59de6456&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports