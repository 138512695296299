<template>
  <section class="faq-section">
    <div class="wrapper">
      <div class="col-lg-12">
        <div class="row">
          <!-- Testimonials Header -->
          <div class="col-lg-5">
            <div class="testimonials">
              <p class="testimonials-sub-head">
                Testimonials From People Who Have Experienced It
              </p>
              <h5>What Customers Say?</h5>
              <p class="testimonials-sub-para">
                Testimonials From People Who Have Experienced It
              </p>
            </div>
          </div>

          <!-- Review Section with Carousel -->
          <div class="col-lg-7">
            <div class="review-section">
              <!-- Images Section -->
              <div class="right-slide">
                <img
                  v-for="(image, index) in images"
                  :key="index"
                  :src="image.src"
                  alt="Customer Image"
                  class="img-1"
                  :class="{ 'active-img': selectedImageIndex === index }"
                  @click="selectImage(index)"
                />
              </div>

              <!-- Content and Review -->
              <div
                v-for="(content, index) in contents"
                :key="index"
                v-show="selectedImageIndex === index"
                class="content-side"
              >
                <p>{{ content }}</p>
                <div class="name-icons">
                  <div class="review-details">
                    <div>
                      <p class="review-name">{{ reviews[index].name }}</p>
                      <p class="review-location">{{ reviews[index].location }}</p>
                    </div>
                    <div class="stars">
                      <span
                        v-for="n in reviews[index].stars"
                        :key="n"
                        class="star"
                      >
                        ★
                      </span>
                    </div>
                  </div>
                  <div class="next-prew">
                    
                    <i
                      class="fa-solid fa-circle-chevron-left"
                      @click="goToPrevious"
                    ></i>
                    <i
                      class="fa-solid fa-circle-chevron-right"
                      
                      @click="goToNext"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      selectedImageIndex: 0, 
      images: [
        { src: require("../assets/images/customer.png") },
      { src: require("../assets/images/customer.png") },
      { src: require("../assets/images/customer.png") }
      ],
      contents: [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply",
        "Survived not only five centuries but also the leap into electronic typesetting. Lorem Ipsum is simplyLorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Contrary to popular belief, Lorem Ipsum is not simply random text. Lorem Ipsum is simply"
      ],
      reviews: [
        { name: "Sarathkumar T", location: "Salem, Tamil Nadu", stars: 5 },
        { name: "John Doe", location: "Chennai, Tamil Nadu", stars: 4 },
        { name: "Jane Smith", location: "Coimbatore, Tamil Nadu", stars: 5 }
      ]
    };
  },
  methods: {
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    goToNext() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      } else {
        this.selectedImageIndex = 0; // Loop back to the first image
      }
    },
    goToPrevious() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      } else {
        this.selectedImageIndex = this.images.length - 1; // Loop to the last image
      }
    }
  }
};
</script>



<style scoped>
@import url("/src/components/ReviewComponent.scss");

</style>
