<template>
  <section class="popular-category-section">
    <div class="wrapper">
      <div class="main">
        <div class="categories_head">
          <h1>Popular Products</h1>
        </div>
        <div class="categories">
  <div
  v-for="data in limitedData"
  :key="data.id"
  @click="triggerPost(data)"
  :class="{ active: selectedCategoryName === data.name }"
  >
    <p>{{ data.name }}</p>
  </div>
</div>

      </div>
   <div class="row">
        <div v-if="isLoading" class="loader mt-4">
          <div class="row">

            <div v-for="data in 4" :key="data.id" class="col-lg-3 col-md-4 col-sm-12 ">
              <p class="shimmer-line-img"></p>
              <p class="shimmer-line"></p>
              <p class="shimmer-line"></p>
            </div>
          </div>
        </div>
        <div v-else class="col-lg-3 col-md-4 col-sm-12" v-for="data in againlimited" :key="data.id">
          <div class="product">
            <div class="img_div">
              <img :src="data.image" alt="" loading="lazy" />
            </div>
            <p class="type" :style="{ backgroundColor: data.type === 'Agency' ? '#B0C800' : 'lightskyblue' }">
              {{ data.type }}
            </p>
            <div class="align">
              <p class="sub_category_name">{{ data.cateName }}</p>
              <p class="product-name">{{ data.name }}</p>
              <div class="date-location">
                <div>
                  <p class="loction-data">
                    <span><img src="../assets/images/location-icon.svg" alt="" loading="lazy" /></span>{{ data.place }},
                    {{ data.district }}
                  </p>
                </div>
                <div>
                  <p class="created">
                    {{
                      new Date(data.created).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })
                    }}
                  </p>
                </div>
              </div>

              <div class="price-view">
                <div>
                  <p class="price">
                    <span><i class="fa-solid fa-indian-rupee-sign" style="color: #018f43;"></i></span> {{ data.price }}/
                    {{ data.quantity }}
                  </p>
                </div>
                <div>
                  <button>View More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
   
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PopularComponent",
  data() {
  return {
   
     isLoading: false, 
    againpayload: {
      categoryid: "",
    },
    category_data: [],
    given_data: [],
    data: [],
    selectedCategoryName: "All", 
  };
},
  computed: {
    limitedData() {
      return this.category_data;
    },
    againlimited() {
      return this.given_data;
    },
  },
  mounted() {
    this.sendData();
    setTimeout(() => {
      if (this.category_data.length > 0) {
        this.triggerPost(this.category_data[0]);
      }
    }, 500);
  },
  methods: {
     async sendData() {
       this.isLoading = true;
    try {
      const response = await axios.get("https://vaiyal-app.herokuapp.com/filtertype");
      const datas = response.data.data || [];
      this.category_data = [
        { id: "All", name: "All" },
        ...datas.map((item) => ({
          id: item.category_id || "N/A",
          name: item.category_name || "No Name",
        })),
      ];
    } catch (error) {
      console.error("Error during POST request:", error);
    }
    finally {
        this.isLoading = false;
      }
  },
 async triggerPost(category) {
  this.isLoading = true;
    this.selectedCategoryName = category.name;

    const payload =
      category.id === "All"
        ? { categoryid: "All" } 
        : { categoryid: category.id };

    try {
      const response = await axios.post("https://vaiyal-app.herokuapp.com/filter", payload);
      const datas = response.data?.data || [];
      this.given_data = datas.map((item) => ({
        cateName: item.category_name || "N/A",
        image: item.images && item.images[0] ? item.images[0] : require("../assets/images/No-Data-list.png"),
        name: item.sub_category_name || "No Name",
        district: item.market_name || "Tamilnadu",
        place: item.place || "",
        created: item.created,
        price: item.price,
        quantity: item.quantity || "",
        type: item.product_category_type,
        lists: item.list || "no lists",
      }));
    } catch (error) {
      console.error("Error during POST request:", error);
    }
    finally {
        this.isLoading = false;
      }
  },
  },
};
</script>

<style scoped>
@import url("/src/components/PopularComponent.scss");
</style>