<template>
  <section>
    <section class="equipment-section">
      <div class="wrapper">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-6 col-md-4">
              <div class="equipment-content">
                <h2>Equipments</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, Lorem Ipsum is
                  simply dummy text of the printing and typesetting industry.
                </p>
                <button><a href="/tamilnadu/agriculture-equipments">All Products</a></button>
              </div>
            </div>
            <div
              v-for="(item, index) in displayedCategories"
              :key="index"
              class="col-lg-3 col-md-4"
            >
              <div class="tractor">
                <div class="Diesel_div" @click="handleClick(item)">
                  <img
                    :src="item.image"
                    :alt="item.category_name"
                    class="diesel-img"
                  />
                  <div class="overlay">
                    <div class="text">
                      <h3>{{ item.category_name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row show-all-equip">
            <div
              v-for="(item, index) in displayedCategoriesIndex"
              :key="index"
              class="col-lg-3 col-md-4"
            >
              <div class="tractor">
                <div class="Diesel_div" @click="handleClick(item)">
                  <img
                    :src="item.image"
                    :alt="item.category_name"
                    class="diesel-img"
                  />
                  <div class="overlay">
                    <div class="text">
                      <h3>{{ item.category_name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-4">
              <div class="equipment-bottom-content" @click="navigateToEquipments">
                <h4>50+ Type Of Products</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      categories: [], 
    };
  },
  computed: {
    displayedCategories() {
      return this.categories.slice(0, 2).map((category) => ({
        ...category, // Preserve all properties for routing
        category_name: category.category_name,
        image: category.images[0], 
      }));
    },
    displayedCategoriesIndex() {
      return this.categories.slice(2, 4).map((category) => ({
        ...category, // Preserve all properties for routing
        category_name: category.category_name,
        image: category.images[0],
      }));
    },
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      try {
        const payload = { categorytype: "farm equipments" };
        const response = await axios.post(
          "https://vaiyal-app.herokuapp.com/categorytype",
          payload
        );
        if (response.data.status === "success") {
          this.categories = response.data.data;
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    generateRoute(item) {
      const baseCategory = 
        item.category_type === 'Live stocks'
          ? 'livestocks'
          : item.category_type
              .replace(/\s/g, '-')
              .replace('farm', 'agriculture');

      if (item.hasSubCategory) {
        return {
          name: 'SubcategoryPage',
          params: {
            categoryId: item.category_id,
            categoryName: item.category_name.replace(/\s/g, '-').toLowerCase(),
            category: baseCategory,
            location: 'tamilnadu',
          },
        };
      } else {
        return {
          name: 'ListComponent',
          params: {
            product: item.category_name.replace(/\s/g, '-').toLowerCase(),
            location: 'tamilnadu',
            category: baseCategory,
          },
        };
      }
    },
    navigateToEquipments() {
      this.$router.push({
        path: '/tamilnadu/agriculture-equipments',
      });
    },
    handleClick(item) {
      const route = this.generateRoute(item);
      this.$router.push(route);
    },
  },
};
</script>


<style>
@import url("/src/components/EquipmentComponent.scss");


</style>
