var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"category-page-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-12"},[_c('div',{staticClass:"title-banner"},[_c('div',{staticClass:"title-content"},[(this.$route.params.categoryName)?_c('h2',[_vm._v(" "+_vm._s(this.$route.params.categoryName.replace(/-/g, " "))+" ")]):_vm._e(),_c('div',{staticClass:"address-section"},[_c('img',{attrs:{"src":require("../assets/images/category-page-home-icon.svg"),"alt":""}}),_vm._m(0),_c('img',{staticClass:"next-page",attrs:{"src":require("../assets/images/next-page.svg"),"alt":""}}),_c('p',{staticClass:"footer-add"},[_c('router-link',{attrs:{"to":this.$route.params.category === 'agriculture-products'
      ? `/${this.$route.params.location}/agriculture-products`
      : this.$route.params.category === 'agriculture-equipments'
      ? `/${this.$route.params.location}/agriculture-equipments`
      : `/${this.$route.params.location}/livestocks`}},[_vm._v(" "+_vm._s(this.$route.params.category === 'agriculture-products' ? 'Agriculture Products' : this.$route.params.category === 'agriculture-equipments' ? 'Agriculture Equipments' : this.$route.params.category === 'livestocks' ? 'Livestocks' : this.$route.params.category)+" ")])],1)])])])]),_vm._m(1)])]),_c('div',{staticClass:"col-lg-12 mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 order-2 order-lg-1"},[_c('SidebarComponent'),_c('NewproductComponent')],1),_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-12 order-1 order-lg-2"},[(_vm.isLoading)?_c('div',[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"row"},_vm._l((4),function(data){return _c('div',{key:data.id,staticClass:"col-6 col-sm-6 col-md-4 col-lg-3"},[_c('p',{staticClass:"shimmer-line-img"}),_c('p',{staticClass:"shimmer-line"}),_c('p',{staticClass:"shimmer-line"})])}),0)])]):_c('div',{staticClass:"subcategorys"},[_c('div',{staticClass:"row mt-4"},_vm._l((_vm.responseData.data),function(subCategory){return _c('div',{key:subCategory.sub_category_id,staticClass:"col-6 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"subcategory-grid",on:{"click":function($event){return _vm.navigateToAllList(subCategory.sub_category_name)}}},[_c('div',{staticClass:"sub-cate"},[_c('img',{staticClass:"sub-img",attrs:{"src":subCategory.images[0] ||
                          require('../assets/images/No-Data-list.png'),"alt":"Subcategory Image"}})]),_c('h4',[_vm._v(_vm._s(subCategory.sub_category_name))]),_c('p',[_vm._v(_vm._s(subCategory.list)+" Items")])])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"footer-add"},[_c('a',{attrs:{"href":"/"}},[_vm._v("  Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12"},[_c('div',{staticClass:"right-ads-category"},[_c('img',{staticClass:"ads-img",attrs:{"src":require("..//assets/images/darlingg.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }