import Vue from 'vue';
import Router from 'vue-router';
import AboutUsPage from '../components/AboutUsPage.vue'; // Your AboutPage component
import MarketPrice from '../components/MarketPrice.vue'
import HistoryPrice from '../components/HistoryPrice.vue'
import HomePage from '../pages/HomePage.vue';
import CategoryPage from '../components/CategoryPage.vue';
import SubcategoryPage from '../components/SubcategoryPage.vue';
import ListComponent from '../components/ListComponent.vue';
import DetailComponent from '../components/DetailComponent.vue';
import EquipDetail from '../components/EquipDetail.vue';
import ContactUsPage from '../components/ContactUsPage.vue';
import PrivacyPolicy from  '../components/PrivacyPolicy.vue';
Vue.use(Router); // Use Router plugin

const routes = [
  { 
    path: '/', 
    name: 'HomePage', 
    component: HomePage 
  },
  { 
    path: '/about', 
    name: 'AboutUsPage', 
    component: AboutUsPage 
  },
  { 
    path: '/contact', 
    name: 'ContactUsPage', 
    component: ContactUsPage 
  },
  { 
    path: '/privacy', 
    name: 'PrivacyPolicy', 
    component: PrivacyPolicy 
  },
  { 
    path: '/marketprice', 
    name: 'MarketPrice', 
    component: MarketPrice ,
    props: true,
  },
  { 
    path: '/historyprice', 
    name: 'HistoryPrice', 
    component: HistoryPrice ,
    props: true,
  },
  { 
    path: '/:location/:category',
    name: "CategoryPage",
    component: CategoryPage,
    props: true,
    meta: {
        title: "CategoryPage", 
      }, 
    },
    { path: "/:location/:category/:categoryName",
        name: "SubcategoryPage",
        component: SubcategoryPage,
        props: true,
        meta: {
            title: "SubcategoryPage", 
          }, 
    },
    { 
      path: '/:location/:category/:categoryName/:product-list/:city?',
      name: "ListComponent",
      component: ListComponent,
      props: true,
      meta: {
          title: "ListComponent", 
        }, 
      },
      {
        path: "/:location/:category/:categoryName?/:product-list/vap:id",
        name: "DetailComponent",
        component: DetailComponent,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
      },
      {
        path: "/:location/:category/:categoryName?/:product-list/vae:id",
        name: "equipmentdetail",
        component: EquipDetail,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
      {
        path: "/:location/:category/:categoryName?/:product-list/vls:id",
        name: "livestockdetails",
        component: DetailComponent,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
     
  // You can add other routes here
];
let preventScroll = true;
const router = new Router({
  routes, // Register the routes
  mode: 'history', 
    scrollBehavior(to, from, savedPosition) {
        if (preventScroll) {
            preventScroll = false; // Reset the flag
            return false; // Maintain the current scroll position
        }

        if (savedPosition) {
            
            return savedPosition;
        } else if (to.hash) {
            const element = document.getElementById(to.hash.slice(1));
            if (element) {
                return { el: element, behavior: "smooth" };
            }
        } else {
            return { top: 0, behavior: "smooth" };
        }
    },
});

export function disableScroll() {
    preventScroll = true;
}

function validateParams(params) {
    const validRegex = /^[a-z0-9-&/]+$/; // Allows only lowercase, numbers, hyphens, & and /
  
    for (const key in params) {
      const value = params[key];
      if (!validRegex.test(value)) {
        console.error(`Invalid parameter: ${key}=${value}`);
        return false;
      }
    }
    return true;
  }
  
router.beforeEach((to, from, next) => {
  
    const newParams = { ...to.params };
    if (!validateParams(to.params)) {
        console.warn("Redirecting due to invalid parameters.");
        // next({ name: "Error" }); // Redirect to a safe route or 404 page
      } else {
        next();
      }
    if (
        to.params.market_name &&
        to.params.market_name !== to.params.market_name.toLowerCase()
    ) {
        newParams.market_name = to.params.market_name.toLowerCase();
    }

    if (
        to.params.location &&
        to.params.location !== to.params.location.toLowerCase()
    ) {
        newParams.location = to.params.location.toLowerCase();
    }

    if (
        to.params.product &&
        to.params.product !== to.params.product.toLowerCase()
    ) {
        newParams.product = to.params.product.toLowerCase();
    }
    if (JSON.stringify(newParams) !== JSON.stringify(to.params)) {
        next({
            name: to.name,
            params: newParams,
            query: to.query,
            hash: to.hash,
        });
    } else {
        next();
    }
});



export default router;
