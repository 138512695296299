<template>
  <section class="privacypolicy-section category-page-section">
    <div class="wrapper">
           <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h2 >Privacy Policy</h2>
                <div class="address-section">
                  <img src="../assets/images/category-page-home-icon.svg" alt="" />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                   <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="">Privacy Policy</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="right-ads-category">
              <img src="../assets/images/darlingg.jpg" alt="" class="ads-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <SidebarComponent />
          <NewproductComponent />
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12">
          <div class="privacypolicy-main-div">
            <div
              class="welcomepage-div"
              v-for="(section, index) in privacySections"
              :key="index"
            >
              <h4 class="privacy-side-heading">{{ section.title }}</h4>
              <p
                class="aboutpolicy"
                v-for="(paragraph, idx) in section.content"
                :key="idx"
              >
                {{ paragraph }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SidebarComponent from "./SidebarComponent.vue";
import NewproductComponent from "./NewproductComponent.vue";

export default {
  name: "PrivacyPolicy",
  data() {
    return {
      privacySections: [
        {
          title: "Welcome to VayalAgro's Privacy Policy",
          content: [
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
            "An unknown printer took a galley of type and scrambled it to make a type specimen book.",
          ],
        },
        {
          title: "How we collect personal information",
          content: [
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
            "An unknown printer took a galley of type and scrambled it to make a type specimen book.",
          ],
        },
        {
          title: "How we use personal information",
          content: [
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
            "An unknown printer took a galley of type and scrambled it to make a type specimen book.",
          ],
        },
        {
          title: "When we disclose your personal information",
          content: [
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
            "An unknown printer took a galley of type and scrambled it to make a type specimen book.",
          ],
        },
        {
          title: "How you can contact us",
          content: [
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
            "An unknown printer took a galley of type and scrambled it to make a type specimen book.",
          ],
        },
      ],
    };
  },
    components: {
    SidebarComponent,
    NewproductComponent
  },
};
</script>

<style>
@import url("/src/components/PrivacyPolicy.scss");
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/NewproductComponent.scss");

</style>