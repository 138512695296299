<template>
   <section class="app-display-section">
       <div class="wrapper">
          <div class="col-lg-12 app-background">
             <div class="row">
                 <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="download-content">
                       <h4>Download VayalAgro App</h4>
                       <p>Lorem Ipsum is simply dummy text of the printing</p>
                       <div class="google-paly">
                          <img src="..//assets/images/googleplay.svg" alt="">
                       </div>
                     </div>
                 </div>
                 <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="app-img-display">
                        <div class="app-home-img">
                            <img src="..//assets/images/Mobile-App.png" alt="">
                        </div>
                    </div>
                     
                 </div>
             </div>
          </div>
       </div>
   </section>
</template>

<script>
export default {

}
</script>

<style>
 @import url("/src/components/AppComponent.scss");
</style>